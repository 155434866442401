import { useEffect, useState } from "react"
import { BaseModal } from "../../../../shared/components/baseModal/BaseModal";
import { Box, Button, Card, CardContent, CardHeader, Icon, IconButton, Stack, TextField, Theme, Typography, useMediaQuery } from '@mui/material';
import { IBranch } from '../../../../shared/models/Branch';
import { Env } from '../../../../shared/environment';
import { BranchService } from "../../../../shared/services/api/branch/BranchService";
import { ModalAddAddress } from "./ModalAddAddress";
import { ModalEditAddress } from "./ModalEditAddress";

interface BaseModalProps {
  branch: IBranch,
  handleClose: () => void,
  open: boolean,
};

export const ModalEditBranch: React.FC<BaseModalProps> = (props) => {
  const { branch, handleClose, open } = props;

  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const [isLoading, setIsLoading] = useState(false);
  const [nameBranch, setNameBranch] = useState("");
  const [openAddAddress, setOpenAddAddress] = useState(false);
  const [openEditAddress, setOpenEditAddress] = useState(false);
  const [branchSelect, setBranchSelect] = useState<IBranch>()


  useEffect(() => {
    setIsLoading(true)
    getBranch()
    setIsLoading(false)
  }, [branch, openAddAddress, openEditAddress])

  const getBranch = () => {
    if (!!branch)
      BranchService.getBranch(branch.id).then((data) => {
        if (data.success && !!data.data) {
          setBranchSelect(data.data)
          setNameBranch(data.data.name)
        }else{
          alert(data.errors[0])
        }
      })
  }

  const handleAddAddressClose = () => {
    setOpenAddAddress(false)
  }
  const handleEditAddressClose = () => {
    setOpenEditAddress(false)
  }

  const handlerSubmit = () => {
    setIsLoading(true)
    const branchUpdate = {
      id: branch?.id,
      name: nameBranch
    }
    BranchService.update(branchUpdate).then((data) => {
      if (data.success) {
        alert("Ponto de venda atualizado com sucesso!")
        handleClose()
      }
    })
    setIsLoading(false)
  }
  return (
    <BaseModal
      handleClose={handleClose}
      open={open}
      title={"Ponto de venda: " + branch?.name}
      minWidth={300}
    >
      <Stack
        direction='column'
        minWidth={smDown ? 300 : 600} marginTop={2}
        paddingTop={1}
        paddingBlock={1}
      >
        <Box sx={Env.FLEX_COLUMN}
          marginTop={1}
        >
          <Stack
            gap={1}
            padding={1}
            width='100%'
            direction='column'
            boxSizing='border-box'
            justifyContent='center'
          >
            <TextField size="small"
              value={nameBranch}
              label='Nome'
              onChange={(e) => setNameBranch(e.target.value)}
            />
          </Stack>
          <Stack
            gap={1}
            padding={1}
            width='100%'
            direction='column'
            boxSizing='border-box'
            justifyContent='center'
          >
            {!!branchSelect?.address ?
              <Card sx={{ boxShadow: 'none' }}>
                <CardContent>
                  <Typography sx={{ marginTop: 2 }} id="modal-modal-title" component="div">
                    Endereço
                  </Typography>
                  <Box sx={{ display: 'flex' }}>
                    <div>
                      <Typography sx={{ fontSize: 12, maxWidth: 150 }} id="modal-modal-title" component="text">
                        {branchSelect.address.street}, {branchSelect.address.number} - {branchSelect.address.district} - {branchSelect.address.city} - {branchSelect.address.state}
                      </Typography>
                    </div>
                    <div>
                      <Typography sx={{ fontSize: 12, maxWidth: 150 }} id="modal-modal-title" component="text">
                        <IconButton size='small' onClick={() => setOpenEditAddress(true)}>
                          <Icon>edit</Icon>
                        </IconButton>
                      </Typography>
                    </div>
                  </Box>
                </CardContent>
              </Card> :
              <Box sx={{ width: '100%', display: 'flex', justifyContent: 'right' }}>
                <Button disabled={isLoading} sx={{ maxWidth: 250 }} variant='contained' onClick={() => setOpenAddAddress(true)}>Adicionar endereço</Button>
              </Box>}
          </Stack>
        </Box>
      </Stack>

      <Stack
        direction='row'
        justifyContent='space-between'
        marginTop={1}
      >
        <Button onClick={handleClose} variant='outlined'>Fechar</Button>
        <Button disabled={isLoading} variant='contained' onClick={() => handlerSubmit()}>Salvar</Button>
      </Stack>
      <ModalAddAddress
        handleClose={handleAddAddressClose}
        open={openAddAddress}
        branch={branch}
      />
      <ModalEditAddress
        handleClose={handleEditAddressClose}
        open={openEditAddress}
        branch={branch}
      />
    </BaseModal>
  )
};
