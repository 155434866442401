import { useNavigate } from "react-router-dom";
import { Box, Button, Paper, TextField, Typography, useMediaQuery } from "@mui/material";

import FlexBox from "../../shared/components/divs/flexBox";
import ImgFundo from "../../shared/assets/images/fundo-fumaca.png";
import ImgResetPassword from "../../shared/assets/icons/icon-reset-password.png";
import { useState } from "react";
import { AuthService } from "../../shared/services/api/auth/AuthService";


export const ForgotPassword: React.FC = () => {
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const smDown = useMediaQuery('(max-width:600px)');

  const navigate = useNavigate();

  const handlerBack = () => {
    navigate("/login");
  }

  const handlerSubmit = () => {
    setIsLoading(true);
    AuthService.forgotPassword(email).then((data) => {
      if (data.success) {
        alert("Foi enviado o email para troca de senha!")
        navigate("/login");
      } else {
        setEmailError(data.errors[0])
      }
    })
    setIsLoading(false);
  }


  return (
    <Box // Body
      width='100vw' height='100vh'
      display='flex' alignItems='center' justifyContent='center'
      sx={{ background: 'linear-gradient(to right, #e2e2e2, #c9d6ff)', }}
    >
      <Box  // Container Father(pai)
        component={Paper}
        elevation={18}
        borderRadius={7}
        sx={{
          boxShadow: '0 5px 15px rgba(0, 0, 0, 0.35)', width: '90%', maxWidth: '900px', transition: 'all 0.6s ease-in-out',

          ...(!smDown && {
            backgroundImage: `url(${ImgFundo})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: smDown ? '230px' : '330px',
            backgroundPositionX: '',
          }
          )
        }}
        display='flex' flexDirection='row' alignItems='center' justifyContent='center'
        height='570px'

        position='relative'
      >
        <FlexBox column jcCenter
          width={`${smDown ? '80%' : '50%'}`} height='100%'
        >

          <FlexBox column aiCenter gap="1rem"
            width="100%" height="50%" sx={{ marginTop: smDown ? '-3rem' : '-10rem' }}
          >

            <img
              src={`${ImgResetPassword}`}
              style={{ width: smDown ? '150px' : '230px' }}
            />



            <Typography variant="h3" fontSize={`${smDown ? '24px' : '28px'}`} fontWeight="bold" color="#6b6b6b">
              Redefinição de senha:
            </Typography>

            <Typography fontSize={`${smDown ? '15px' : '17px'}`} color="#6b6b6b">
              Digite e-mail para o qual deseja redefinir sua senha:
            </Typography>


            <FlexBox width={smDown ? "100%" : "80%"} column gap="1rem"
              sx={{ margin: '.5rem 0 0 0' }}
            >
              <TextField
                fullWidth
                type='email'
                label='Email'
                size="small"
                variant="filled"
                value={email}
                disabled={isLoading}
                error={!!emailError}
                helperText={emailError}
                onKeyDown={() => setEmailError('')}
                onChange={e => setEmail(e.target.value)}
              />

              <FlexBox
                fullWidth
                row
                gap="1rem"
                sx={{ justifyContent: 'space-between' }}
              >
                <Button variant="outlined"
                  sx={{ borderRadius: "10px" }}
                  onClick={handlerBack}
                >
                  Voltar
                </Button>
                <Button variant="contained"
                  sx={{ borderRadius: "10px" }}
                  disabled={isLoading}
                  onClick={handlerSubmit}
                >
                  Enviar
                </Button>
              </FlexBox>
            </FlexBox>

          </FlexBox>

        </FlexBox>

      </Box>
    </Box >

  );
};
