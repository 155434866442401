import { Icon, IconButton, LinearProgress, Pagination, Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow, Typography } from "@mui/material"
import { Environment } from "../../../shared/environment"
import { EstoquesService, IProdutoEstoque } from "../../../shared/services/api/estoques/EstoquesService";
import { Dispatch, SetStateAction, useState } from "react";
import { ModalEditarProduto } from "../components/Modal-EditarProduto";
import { SetURLSearchParams, useNavigate } from "react-router-dom";
import FlexBox from "../../../shared/components/divs/flexBox";


interface TabelaEstoqueProps {
  rows: IProdutoEstoque[];
  isLoading: boolean;
  totalCount: number;
  setRows: Dispatch<SetStateAction<IProdutoEstoque[]>>
  pagina: number;
  busca: string;
  setSearchParams: SetURLSearchParams;
}

export const TabelaEstoque: React.FC<TabelaEstoqueProps> = ({ rows, setRows, isLoading, totalCount, setSearchParams, pagina, busca }) => {

  const navigate = useNavigate();
  const [isOpenEdit, setIsOpenEdit] = useState(false);


  function handleDelete(id: number) {

    let message = "Deseja apagar este produto do estoque ?";
    if (window.confirm(message)) {

      EstoquesService.deleteById(id).then(
        result => {
          if (result.success) {
            setRows(oldRow => oldRow.filter(row => row.id !== id));
          } else {
            alert(result.errors + result.message);
          }
        }
      );
    };
  };

  return <>
  
    <FlexBox fullWidth jcEnd>
      <Typography
        variant="h6"
        sx={{ fontSize: '17px' }}
      >
        Foram encontrados um total de {totalCount} clientes
      </Typography>
    </FlexBox>


    <TableContainer component={Paper} variant='outlined' sx={{ m: 1, width: 'auto' }}>

      <Table>
        <TableHead>

          <TableRow>
            <TableCell>Nome</TableCell>
            <TableCell>Quantidade</TableCell>
            <TableCell>Valor</TableCell>
            <TableCell>Custo</TableCell>
            <TableCell width={100}>Ações</TableCell>
          </TableRow>

        </TableHead>
        <TableBody>

          {
            Array.isArray(rows) && (

              rows.map(row => (
                <>
                  <TableRow key={row.id}
                    onClick={() => navigate(`/produto/detalhe/${row.id}`)}
                    sx={{ cursor: 'pointer', '&:hover': { backgroundColor: '#bbbbbb3c' }, transition: '.3s' }}
                  >

                    <TableCell><Typography fontWeight={500} >{row.name}</Typography></TableCell>
                    <TableCell><Typography fontWeight={500} >{row.quantity}</Typography></TableCell>
                    <TableCell><Typography fontWeight={500} color={"#4eb34e"}>R$ {row.value}</Typography></TableCell>
                    <TableCell>R$ {row.cost}</TableCell>


                    <TableCell>

                      <IconButton size='small'
                        onClick={
                          (event) => {
                            event.stopPropagation(); // Previne a propagação do evento para a TableRow, jogando a propagação pro IconButton
                            setIsOpenEdit(true);
                          }
                        }
                      >
                        <Icon>edit</Icon>
                      </IconButton>

                      <IconButton size='small'
                        onClick={
                          (event) => {
                            event.stopPropagation();
                            handleDelete(row.id);
                          }
                        }
                      >
                        <Icon>delete</Icon>
                      </IconButton>

                    </TableCell>

                  </TableRow>

                  <ModalEditarProduto
                    isOpen={isOpenEdit}
                    setIsOpenEdit={setIsOpenEdit}
                    produto={row}
                  />
                </>
              ))
            )
          }



        </TableBody>

        {totalCount === 0 && !isLoading && (
          <caption>{Environment.LISTAGEM_VAZIA}</caption>
        )}

        <TableFooter>
          {isLoading && (
            <TableRow>
              <TableCell colSpan={5} // <--- colSpan( qntdade de colunas vai ocupar de espaço )
              >
                <LinearProgress variant='indeterminate' />
              </TableCell>
            </TableRow>
          )}
          {(totalCount > 0 && totalCount > Environment.LIMITE_DE_LINHAS) && (
            <TableRow>
              <TableCell colSpan={5}>
                <Pagination
                  page={pagina}
                  count={Math.ceil(totalCount / Environment.LIMITE_DE_LINHAS)}
                  onChange={(_, newPage) => setSearchParams({ busca, pagina: newPage.toString() }, { replace: true })}
                />
              </TableCell>
            </TableRow>
          )}
        </TableFooter>
      </Table>
    </TableContainer>
  </>
}
