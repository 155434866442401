import { Dispatch, FormEvent, SetStateAction, useRef, useState, useEffect } from "react";
import { BaseModal } from "../../../shared/components/baseModal/BaseModal";
import FlexBox from "../../../shared/components/divs/flexBox";
import { Button, TextField, Theme, useMediaQuery } from "@mui/material";
import { EstoquesService, IProdutoEstoque, ProdutoEstoque } from "../../../shared/services/api/estoques/EstoquesService";

interface ModalEditarProdutoProps {
  isOpen: boolean;
  setIsOpenEdit: Dispatch<SetStateAction<boolean>>;
  produto: IProdutoEstoque; // Prop para o produto a ser editado
}

export const ModalEditarProduto: React.FC<ModalEditarProdutoProps> = ({ isOpen, setIsOpenEdit, produto }) => {

  function handleClose() {
    setIsOpenEdit(false);
  };

  const [isLoading, setIsLoading] = useState(false);

  const [name, setName] = useState(produto.name);
  const [quantity, setQuantity] = useState(produto.quantity);
  const [cost, setCost] = useState(produto.cost);
  const [value, setValue] = useState(produto.value);


  function handleSubmit(e: FormEvent) {
    e.preventDefault();
      
      const updatedProduto = new ProdutoEstoque(
        produto.id,
        name,
        quantity,
        cost,
        value
      );

      setIsLoading(true);
      EstoquesService.updateById(updatedProduto).then( 
        result => {
          setIsLoading(false);
          if (result.success && result.data) {
            alert("Produto atualizado com sucesso!");
            window.location.reload();
            setIsOpenEdit(false);

          } else {
            alert(result.errors + result.message);
          }
        }
      );

    console.log(name,quantity, cost, value );
  };

  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

  return (
    <BaseModal
      minWidth={smDown ? 290 : 350}
      open={isOpen}
      handleClose={()=>setIsOpenEdit(false)}
      title="Editar Produto"
    >
      <FlexBox component="form"
        minHeight="100px"
        onSubmit={handleSubmit}
        column gap={10} marginTop={5}
      >

        <TextField
          type="text"
          name="name"
          label="Nome"
          value={name}
          onChange={e => setName(e.target.value)}
          required
        />

        <TextField
          type="number"
          name="quantity"
          label="Quantidade"
          value={quantity}
          onChange={e => setQuantity(parseInt(e.target.value))}
          required
        />
        <TextField
          type="number"
          name="cost"
          label="Custo"
          value={cost}
          onChange={e => setCost(parseInt(e.target.value))}
          required
        />
        <TextField
          type="number"
          name="value"
          label="Valor de Venda"
          value={value}
          onChange={e => setValue(parseInt(e.target.value))}
          required
        />

        <FlexBox row jcBetween marginTop={8} >
          <Button variant="outlined"
            onClick={handleClose}
          >
            Fechar
          </Button>
          <Button variant="contained"
            type="submit"
            disabled={isLoading}
          >
            {isLoading ? 'Salvando...' : 'Salvar'}
          </Button>
        </FlexBox>

      </FlexBox>
    </BaseModal>
  );
}
