import React, { Dispatch, SetStateAction, useState } from 'react'
import { Button, CircularProgress, TextField, Theme, useMediaQuery } from '@mui/material';
import { BaseModal } from '../../../../shared/components/baseModal/BaseModal';
import FlexBox from '../../../../shared/components/divs/flexBox';
import { BranchService } from '../../../../shared/services/api/branch/BranchService';
import { useAuthContext } from '../../../../shared/contexts';

interface IModalNovaBranch {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}

export const ModalNovaBranch: React.FC<IModalNovaBranch> = ({
  isOpen,
  setIsOpen,
}) => {

  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const { user } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState('');
  const [nameError, setNameError] = useState('');
  const [doc, setDoc] = useState('');
  const [docError, setDocError] = useState('');

  var branch = {
    name: name,
    cnpjCpf: doc
  }

  const handleClose = () => { setIsOpen(false) };

  const handleSubmit = () => {
    if (user.admin) {

      setIsLoading(true);

      BranchService.insertBranch(branch).then(result => {
        if (result.success) {
          alert("Ponto de venda cadastrada com sucesso!");
          window.location.reload();
        }
        else {
          setNameError('Erro ao cadastrar ponto de venda!');
        }
      })

    }
    else {
      setNameError("Apenas usuarios adiministradores podem inserir novas pontos de venda!");
    }

  };

  return (
    <BaseModal
      open={isOpen}
      handleClose={handleClose}
      title="Novo ponto de venda"
      minWidth={smDown ? 300 : 500}
    >
      <TextField
        fullWidth
        type='text'
        label='Nome do ponto de venda'
        size="small"
        variant="outlined"
        value={name}
        disabled={isLoading}
        error={!!nameError}
        helperText={nameError}
        onKeyDown={() => setNameError('')}
        onChange={e => setName(e.target.value)}

        sx={{ marginTop: 2 }}
      />
      <TextField
        fullWidth
        type='text'
        label='CNPJ ou CPF'
        size="small"
        variant="outlined"
        value={doc}
        disabled={isLoading}
        error={!!docError}
        helperText={docError}
        onKeyDown={() => setDocError('')}
        onChange={e => setDoc(e.target.value)}

        sx={{ marginTop: 2 }}
      />
      <FlexBox row fullWidth jcBetween marginTop={5}>
        <Button
          onClick={handleClose}
        >
          fechar
        </Button>
        <Button
          variant='contained'
          onClick={handleSubmit}
          disabled={isLoading}
          endIcon={isLoading ? <CircularProgress variant='indeterminate' color='inherit' size={20} /> : undefined}
        >
          Salvar
        </Button>
      </FlexBox>

    </BaseModal>
  )
};
