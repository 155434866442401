import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { TextField, FormGroup, FormControlLabel, Checkbox, Tooltip, IconButton, Icon, Button, Typography } from "@mui/material";
import FlexBox from "../../../../../shared/components/divs/flexBox";
import { IOptionNewClient } from "../Modal-NovoCLiente2";
import { NewClientStorage } from "../../../../../shared/services/storage/newClientStorage";


interface SectionNameProps {
  name: string;
  setName: Dispatch<SetStateAction<string>>;
  errorName: string;
  optionsNew: IOptionNewClient[];
  setOptionsNew: Dispatch<SetStateAction<IOptionNewClient[]>>;
  position: number;
  handleClose: () => void;
  handleSubmit(): void
  handleAdvance: () => void
}


export const SectionName: React.FC<SectionNameProps> = (props) => {
  const { name, setName, optionsNew, position, setOptionsNew, handleClose, handleAdvance, handleSubmit, errorName
  } = props;
  const [options, setOptions] = useState<IOptionNewClient[]>([]);
  const serviceStorage = new NewClientStorage()
  useEffect(() => {
    const itens = serviceStorage.getConfigNewUser();
    if(!!itens)
    {
      setOptions(itens)
    }else{
      setOptions(optionsNew)
    }
  }, [options])

  function handleChangeOption(index: number) {
    const modify = optionsNew;
    modify[index].active = options[index].active ? false : true;
    serviceStorage.saveConfigNewUser(modify);
    setOptions(modify)
    setOptionsNew(modify)
  };
  function isCheck(index: number) {
    return optionsNew[index].active
  }

  function selectSubmit() {
    if (optionsNew.filter(x => x.active).length > position) {
      handleAdvance();
    } else {
      handleSubmit();
    }
  };

  return (
    <>
      <FlexBox
        minHeight="100px"
        column gap={10}
        marginTop="40px"
        component="form"
        onSubmit={selectSubmit}
      >
        <TextField
          fullWidth
          required
          label="Nome Completo"
          value={name}
          error={!!errorName}
          helperText={errorName}
          onChange={e => setName(e.target.value)}
        />

        <FlexBox row jcBetween>
          <FormGroup>
            <FormControlLabel control={<Checkbox size="small"
              checked={isCheck(1)}
              onChange={() => handleChangeOption(1)} />} label="Contato"
            />
            <FormControlLabel control={<Checkbox size="small"
              checked={isCheck(2)}
              onChange={() => handleChangeOption(2)} />} label="Endereço"
            />
            <FormControlLabel control={<Checkbox size="small"
              checked={isCheck(3)}
              onChange={() => handleChangeOption(3)} />} label="Documento"
            />
          </FormGroup>
        </FlexBox>
      </FlexBox>

      <FlexBox row jcBetween marginTop="70px">
        <Button variant="text" onClick={handleClose}>
          Fechar
        </Button>

        {(optionsNew.filter(x => x.active).length > position + 1) && (
          <Button variant="outlined" endIcon={<Icon>forward_rounded</Icon>}
            type="submit" onClick={selectSubmit}
          >
            Avançar
          </Button>
        )}

        {(optionsNew.filter(x => x.active).length == position + 1) && (
          <Button variant="contained"
            type="submit" onClick={selectSubmit}
          >
            Salvar
          </Button>
        )}
      </FlexBox>
    </>
  );
};
