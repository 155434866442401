import Box from '@mui/material/Box';
import { useState } from "react"
import { BaseModal } from "../../../../shared/components/baseModal/BaseModal";
import { Button, Stack, TextField, Theme, useMediaQuery } from '@mui/material';

import { IUser } from '../../../../shared/models/User';
import { Env } from '../../../../shared/environment';
import { IRole } from '../../../../shared/models/Role';
import { RoleService } from '../../../../shared/services/api/role/RoleService';

interface BaseModalProps {
  user: IUser | undefined
  role: IRole | undefined,
  handleClose: () => void,
  open: boolean,
};

export const ModalRoleEdit: React.FC<BaseModalProps> = (props) => {
  const { user, role, handleClose, open } = props;
  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm')); //   sm = 600px  //  md = 900px  //  lg = 1200px
  const [isLoading, setIsLoading] = useState(false);
  const [roleSelect, setRoleSeletec] = useState(0);
  const roles = [
    {
      value: 0,
      label: 'Comum'
    },
    {
      value: 1,
      label: 'Gerente',
    },
    {
      value: 2,
      label: 'Administrador',
    },
  ];

  const handlerSubmit = () => {
    setIsLoading(true)
    if (role?.id != null)
      RoleService.putRole(role.id, roleSelect).then((data) => {
        if (data.success) {
          alert("Perfil atualizado com sucesso!")
          handleClose()
        }
      })
    setIsLoading(false)
  }

  return (
    <BaseModal
      handleClose={handleClose}
      open={open}
      title={`Editar perfil de acesso`}
      minWidth={300}
    >
      <Stack
        direction='column'
        minWidth={smDown ? 300 : 600}
        paddingTop={1}
      >

        <Box sx={Env.FLEX_COLUMN}
          marginTop={1}
        >
          <Stack
            gap={2}
            padding={2}
            width='100%'
            direction='column'
            boxSizing='border-box'
            justifyContent='center'
          // border='1px solid black'
          >
            <TextField
              label='Usuario'
              value={user?.name}
              disabled={true}
            />
            <TextField
              label='Empresa'
              value={role?.branch.name}
              disabled={true}
            />
            <TextField
              label='Perfil'
              select
              SelectProps={{ native: true }}
              defaultValue={role?.level}
              onChange={(e) => setRoleSeletec(parseInt(e.target.value))}
              required
            >
              {roles.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </TextField>

            <Stack
              direction='row'
              justifyContent='space-between'
            >
              <Button onClick={handleClose}>Fechar</Button>
              <Button disabled={isLoading} onClick={handlerSubmit}>Salvar</Button>
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </BaseModal>
  )
};
