import React, { useState } from 'react';
import { Box, Divider, Icon, List, ListItemButton, ListItemIcon, ListItemText, Typography, useMediaQuery, useTheme } from '@mui/material';

import { Env } from '../../../environment';
import { useNavigate } from 'react-router-dom';
import { ModalFilialSelect } from '../components';
import AvatarNoImg from '../../avatar/AvatarNoImg';
import { ListItemLink } from '../components/ListItemLink';
import { useDrawerContext, useAppThemeContext, useAuthContext } from '../../../contexts';

import Logo from '../../../assets/images/OticaSystems_roxo.png';


interface MenuLateralContentProps {
  pathAdmin: boolean;
}

export const MenuLateralContent: React.FC<MenuLateralContentProps> = ({ pathAdmin }) => {

  const theme = useTheme();
  const nav = useNavigate();
  const { logout, roles } = useAuthContext();
  const { toggleTheme } = useAppThemeContext();
  const { toggleDrawerOpen, drawerOptions, drawerOptionsAdmin } = useDrawerContext();

  const [isOpen, setIsOpen] = useState(false);
  // const [isLoading, setIsLoading] = useState(false);


  function handleNavigatePainel() {
    !pathAdmin && nav('/admin');
    pathAdmin && nav('/painel');
  };

  function handleOpenModal() {
    setIsOpen(true);
  };

  function handleLogoutDialog() {
    const userConfirmed = window.confirm("Você deseja SAIR ?")
    if (userConfirmed) {
      logout();
    };
  };


  const smDown = useMediaQuery(theme.breakpoints.down('sm')); // sm = 600px / md = 900px lg = 1200px

  const branchName = roles !== null && roles?.filter(role => role.isSelected === true)[0]?.branch?.name;

  return (

    <Box width={theme.spacing(27)} display='flex' flexDirection='column'
      height="110%"
      maxHeight="900px"
    >

      <Box minHeight='50px' width="100%" display='flex' alignItems='center' justifyContent={'center'}>
        {
          smDown && (
            <img style={{margin: '10px'}} width={150} src={Logo} />
          )
        }
        {
          !smDown && (
            <img style={{margin: '10px'}} width={150} src={Logo} />
          )
        }
      </Box>

      <Divider />

      <Box flex={1}
        /////flex={1} serve para fazer com que o container ocupe todo o espaço disponível
        borderRadius={Env.BD_RADIUS}
        sx={{ display: 'flex', gap: '5px', flexDirection: 'column' }}
      >

        {
          pathAdmin && (

            <List component='nav'>
              {drawerOptionsAdmin.map((drawerOption) => (
                <ListItemLink
                  to={drawerOption.path}
                  key={drawerOption.path}
                  icon={drawerOption.icon}
                  label={drawerOption.label}
                  onClick={toggleDrawerOpen}
                />
              ))}
            </List>
          )
        }
        {
          !pathAdmin && (

            <List component='nav' sx={{ display: 'flex', gap: '5px', flexDirection: 'column' }}>
              {drawerOptions.map((drawerOption: any) => (
                <ListItemLink
                  to={drawerOption.path}
                  key={drawerOption.path}
                  icon={drawerOption.icon}
                  label={drawerOption.label}
                  onClick={toggleDrawerOpen}
                />
              ))}
            </List>
          )
        }
      </Box>


      <Box>
        <List component='nav'>

          <ListItemButton onClick={handleNavigatePainel}>
            <ListItemIcon>
              <Icon>app_registration</Icon>
            </ListItemIcon>
            <ListItemText primary="Painel Empresa" />
          </ListItemButton>

          {
            (roles.length > 1 && !pathAdmin) && (
              <ListItemButton>
                <ListItemIcon onClick={handleOpenModal} >
                  <Icon>repeat_on</Icon>
                  {/* <Icon>store</Icon> */}
                </ListItemIcon>
                <ListItemText primary={branchName} onClick={handleOpenModal} />

                <ModalFilialSelect
                  isOpen={isOpen}
                  setIsOpen={setIsOpen}
                />
              </ListItemButton>
            )
          }


          {
            /* 
              <ListItemButton onClick={toggleTheme}>
                <ListItemIcon>
                  <Icon>dark_mode</Icon>
                </ListItemIcon>
                <ListItemText primary="Alternar tema" />
              </ListItemButton> 
            */
          }


          <ListItemButton onClick={handleLogoutDialog}>
            <ListItemIcon>
              <Icon>logout</Icon>
            </ListItemIcon>
            <ListItemText primary="Sair" />
          </ListItemButton>
        </List>
      </Box>
    </Box >
  );
};
