import { IAddress } from "../../../models";
import { IResponseAPI } from "../../../models/base/IResponseAPI";
import { Api } from "../axios-config";


const updateAddress = async (address: object): Promise<IResponseAPI<IAddress> | IResponseAPI<IAddress>> => {
  const { data } = await Api.put(`/otica/Address`, address).catch((data) => {
    return data.response;
  });
  return data;
};

export const AddressService = {
  updateAddress
};