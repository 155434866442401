import { useEffect, useState } from "react";
import { LayoutBaseDePagina } from "../../../shared/layouts";
import { SubscriptionTable } from "./components/SubscriptionTable";
import { SubscriptionService } from "../../../shared/services/api/subscription/SubscriptionService";
import { ISubscription } from "../../../shared/models/Subscription";

export const SubscriptionList = () => {
  const [subscriptions, setSubscriptions] = useState<ISubscription[]>();
  useEffect(() => {
    SubscriptionService.getSubscriptions()
      .then((result) => {
        if (result != undefined &&result?.success && result?.data != undefined) {
          setSubscriptions(result.data)
        }
      });
  }, []);
  
  return (
    <LayoutBaseDePagina
      titulo="Assinaturas"
    >
      {subscriptions && (subscriptions.map((subscription) => <SubscriptionTable subscription={subscription}/>))}

    </LayoutBaseDePagina>
  )
};

