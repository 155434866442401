import { styled } from '@mui/material/styles';
import * as Pax  from 'react-apexcharts';

export const Chart = styled(Pax.default)``;





// import dynamic from 'next/dynamic';
// import { styled } from '@mui/material/styles';
// import Chart from 'react-apexcharts';

// const ApexChart = dynamic(() => ), {
//   ssr: false,
//   loading: () => null
// });

// export const Chart = styled(ApexChart)``;