/* eslint-disable */
import { useLocation } from "react-router-dom";
import React, { ReactNode, useEffect, useState } from "react";
import { Box, Card, CircularProgress } from "@mui/material";

import FlexBox from "../divs/flexBox";
import { Env } from "../../environment";
import { MenuLateralContainer, MenuLateralContent } from "./sections";
import { useAuthContext } from "../../contexts";

interface IAppThemeProviderProps {
  children: ReactNode
}


export const MenuLateral: React.FC<IAppThemeProviderProps> = ({ children }) => {

  const location = useLocation();
  const pathAdmin = location.pathname.includes('admin');

  const { globalLoading } = useAuthContext();



  //   useMediaqueryLanna() /////////////////////////////////////////////////
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [])
  const down600 = windowWidth < 600;
  /////////////////////////////////////////////////////////////////////////




  return (
    <Box    // BODY 
      width='100vw' height='100vh'
      display='flex' justifyContent='center'
      sx={{ overflowX: 'auto', overflowY: 'auto' }}
    >
      <Box // CONTAINER
        maxWidth='1400px' height='85vh'
        marginTop='2vw'
        display='flex' flexDirection='row' gap={3}
        sx={down600 ? { width: '100vw' } : { width: '90vw' }}
      >




        <MenuLateralContainer             ////// Menu lateral
        >
          
          <MenuLateralContent
            pathAdmin={pathAdmin}
          />

        </MenuLateralContainer>





        <FlexBox                      // Content-Páginas
          flex={1} minHeight="100%"
          justifyContent='center'
        >
          <Box                       // FILHO 
            component={Card}
            elevation={down600 ? 0 : 2}
            height='min-content'
            flex={1}
            borderRadius={Env.BD_RADIUS}
            display='flex' flexDirection='column' gap={2}
            paddingX={4}
            paddingBottom={8}
            sx={down600 ? { backgroundColor: "transparent" } : {}}
          >
            {
              globalLoading && (
                <FlexBox jcCenter aiCenter height="500px">
                  <CircularProgress size={100} />
                </FlexBox>
              )
            }
            {
              !globalLoading && children
            }
          </Box>
        </FlexBox>
      </Box>
    </Box>
  )
};