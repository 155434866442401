import { Modal, Box, Typography, Theme, SxProps, Card } from "@mui/material"
import { Dispatch, ReactNode, SetStateAction } from "react";

interface BaseModalProps {
  handleClose?: Dispatch<SetStateAction<boolean>>,
  children: ReactNode,
  title: string,
  open: boolean,
  minWidth: number | string,
  
  // mostrarBotaoNext: boolean,
  // textoBotaoNext: string,
  // aoClicarBotaoNext: () => void;
}; 

export const BaseModal: React.FC<BaseModalProps> = (props) => {
  const { 
    open, 
    handleClose, 
    children, 
    title, 
    minWidth 
  } = props;


  const style: SxProps<Theme> = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: minWidth,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: 6
  };

  
  return (

    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style} component={Card}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          {title}
        </Typography>
        <Box id="modal-modal-description" sx={{ mt: 2 }}>
          {children}
        </Box>
      </Box>
    </Modal>

  )
};