import { Box, Typography, Button, Theme, useMediaQuery } from "@mui/material";

interface IRightContainerProps {
  active: boolean;
  handleResetStates: () => void;
}

export const RightContainer: React.FC<IRightContainerProps> = ({

  active,
  handleResetStates,

}) => {

  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm')); //   sm = 600px  //  md = 900px  //  lg = 1200px

  var ToggleContainerSignin: React.CSSProperties = {

    top: '0',
    position: 'absolute',
    borderRadius: '25px 150px 100px 25px',
    transition: 'all 0.6s ease-in-out',
  };
  
  var ToggleContainerSignin_active: React.CSSProperties = {
  
    top: '0',
    position: 'absolute',
    borderRadius: '150px 25px 25px 100px',
    transition: 'all 0.6s ease-in-out',
    ...(smDown && { margin: '0 0 0 20%' }), //para celular
  
  
    transform: 'translateX(100%)',
  };

  return (
    <Box width='50%' height='100%'

      borderRadius=' 140px 0 0 100px'
      display='flex' alignItems='center' justifyContent='center'
      bgcolor='linear-gradient(to right, #5c6bc0, #512da8)'
      sx={{
        transition: 'all 0.6s ease-in-out',
        background: 'linear-gradient(to right, #5c6bc0, #512da8)',
        ...(active ? ToggleContainerSignin : ToggleContainerSignin_active),
        ...(smDown && { width: '40%' }) //para celular
      }}
      
    >


      {!active && (
        
        <Box width='80%' height='45%'
          display='flex' alignItems='center' justifyContent='center' flexDirection='column'
          sx={{ transition: 'all 0.6s ease-in-out' }}
        >
          <Typography variant='h4' align='center' fontWeight={600} marginY={2} color='#fff'>
            Olá !!!!
          </Typography>
          <Typography fontSize='14px' textAlign='center' color='#fff'>
            Se sua ótica ainda não for registrada, registre-se clicando no botão abaixo !!
          </Typography>
          <Button
            variant="outlined"
            onClick={handleResetStates}
            sx={{ marginTop: 4, color: '#fff', border: '1px solid #fff' }}
          >
            Registrar
          </Button >
        </Box>
      )
      }


      {active && (

        <Box width='80%' height='45%'
          display='flex' alignItems='center' justifyContent='center' flexDirection='column'
          sx={{ transition: 'all 0.6s ease-in-out' }}
        >
          <Typography variant='h4' align='center' fontWeight={600} marginY={2} color='#fff'>
            Bem-vindo !
          </Typography>
          <Typography fontSize='14px' textAlign='center' color='#fff'>
            Insira seus dados pessoais para cadastrar uma conta Administradora da sua ótica! Logo em seguida cadastre sua(s) ótica(s)!
          </Typography>
          <Button
            variant="outlined"
            onClick={handleResetStates}
            sx={{ marginTop: 4, color: '#fff', border: '1px solid #fff' }}
          >
            Entrar
          </Button >
        </Box>
      )
      }


    </Box>
  )
}