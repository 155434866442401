export enum IStatusPaymentEnum {
    'Novo',
    'Processando',
    'Confirmado',
    'Recebido',
    'Reembolsado',
    'Atrasado',
    'Reprovado',
    'Manual',
    'Pendente',
}