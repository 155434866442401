import { IAddress } from "../../../models";
import { IResponseAPI } from "../../../models/base/IResponseAPI";
import { Api } from "../axios-config";


const getCep = async (cep: string): Promise<IResponseAPI<IAddress[]> | IResponseAPI<IAddress[]>> => {
  const { data } = await Api.get(`/location/Location/Search?cep=${cep}`).catch((data) => {
    return data.response;
  });
  return data;
};

export const LocationService = {
  getCep,

};