import { Dispatch, FormEvent, SetStateAction, useRef, useState } from "react";
import { BaseModal } from "../../../shared/components/baseModal/BaseModal"
import FlexBox from "../../../shared/components/divs/flexBox";
import { Button, CircularProgress, TextField, Theme, useMediaQuery } from "@mui/material";
import { EstoquesService, InsertProdutoDto  } from "../../../shared/services/api/estoques/EstoquesService";

interface ModalNovoProdutoProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}

export const ModalNovoProduto: React.FC<ModalNovoProdutoProps> = ({ isOpen, setIsOpen }) => {

  function handleClose() {
    setIsOpen(false);
  };


  const [isLoading, setIsLoading] = useState(false);

  const nameRef = useRef<HTMLInputElement>(null);
  const quantityRef = useRef<HTMLInputElement>(null);
  const costRef = useRef<HTMLInputElement>(null);
  const valueRef = useRef<HTMLInputElement>(null);




  function handleSubmit(e: FormEvent) {
    e.preventDefault();
    if (nameRef.current && quantityRef.current && costRef.current && valueRef.current) {

      const produto = new InsertProdutoDto(
        nameRef.current.value,
        parseInt(quantityRef.current.value),
        parseFloat(costRef.current.value),
        parseFloat(valueRef.current.value)
      );
      // console.log("Produto:", produto);

      EstoquesService.create(produto).then(
        result => {
          setIsLoading(true);
          if(result.success && result.data) {
            setIsLoading(false);
            alert("Produto cadastrado com sucesso!");
            window.location.reload();
          }else{
            alert(result.errors + result.message);
          }
        }
      );

    };
  };

  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));


  return (
    <BaseModal
      minWidth={smDown ? 290 : 350}
      open={isOpen}
      handleClose={handleClose}
      title="Novo Produto"
    >
      <FlexBox component="form"
        minHeight="100px"
        onSubmit={handleSubmit}
        column gap={10} marginTop={5}
      >

        <TextField
          type="text"
          name="name"
          label="Nome"
          inputRef={nameRef}
          required
        />

        <TextField
          type="number"
          name="quantity"
          label="Quantidade"
          inputRef={quantityRef}
          required
        />
        <TextField
          type="number"
          name="cost"
          label="Custo"
          inputRef={costRef}
          required
        />
        <TextField
          type="number"
          name="value"
          label="Valor de Venda"
          inputRef={valueRef}
          required
        />

        <FlexBox row jcBetween marginTop={8} >
          <Button variant="outlined"
            onClick={handleClose}
          >
            Fechar
          </Button>
          <Button variant="contained"
            type="submit"
            disabled={isLoading}
            endIcon={isLoading ? <CircularProgress variant='indeterminate' color='inherit' size={20} /> : undefined}
          >
            Salvar
          </Button>
        </FlexBox>

      </FlexBox>
    </BaseModal>
  )
}
