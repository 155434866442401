import { IAddress } from "../../../models";
import { IResponseAPI } from "../../../models/base/IResponseAPI";
import { IBranch } from "../../../models/Branch";
import { Api } from "../axios-config";

const deleteBranch = async (branchId: number): Promise<IResponseAPI<IBranch> | IResponseAPI<IBranch>> => {
  const { data } = await Api.delete(`/otica/Branch/${branchId}`).catch((data) => {
    return data.response;
  });
  return data;
};

const insertBranch = async (branch: any): Promise<IResponseAPI<IBranch> | IResponseAPI<IBranch>> => {
  var branchRQ = {
    name: branch.name,
    cnpjCpf: branch.cnpjCpf,
    subscription: {
      typeDayPay: 0,
      value: 149.90
    }
  }
  const { data } = await Api.post(`/otica/Branch`, branchRQ).catch((data) => {
    return data.response;
  });
  return data;
};

const getBranchs = async (): Promise<IResponseAPI<IBranch[]> | IResponseAPI<IBranch[]>> => {
  const { data } = await Api.get(`/otica/Branch`).catch((data) => {
    return data.response;
  });
  return data;
};
const getBranch = async (id: number): Promise<IResponseAPI<IBranch> | IResponseAPI<IBranch>> => {
  const { data } = await Api.get(`/otica/Branch/${id}`).catch((data) => {
    return data.response;
  });
  return data;
};

const update = async (branch: object): Promise<IResponseAPI<IBranch> | IResponseAPI<IBranch>> => {
  const { data } = await Api.put(`/otica/Branch/`, branch).catch((data) => {
    return data.response;
  });
  return data;
};

const insertAddress = async (address: object): Promise<IResponseAPI<IAddress> | IResponseAPI<IAddress>> => {
  const { data } = await Api.post(`/otica/Branch/Address`, address).catch((data) => {
    return data.response;
  });
  return data;
};

export const BranchService = {
  getBranchs,
  deleteBranch,
  insertBranch,
  getBranch,
  insertAddress,
  update
};