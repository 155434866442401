import { Environment } from "../../../environment";
import { IResponseAmount } from "../../../models/base/IResponseAmount";
import { IResponseAPI } from "../../../models/base/IResponseAPI";
import { IClient, IClientInsert } from "../../../models/Clients/client";
import { Api } from "../axios-config";


const get = async (page = 1, filter = ''): Promise<IResponseAPI<IResponseAmount<IClient[]>> | Error | undefined> => {

  const urlRelativa = `/otica/client?name=${encodeURIComponent(filter)}&page=${page}&pageSize=${Environment.LIMITE_DE_LINHAS}`;
  const { data } = await Api.get(urlRelativa).catch(data => {
    return data.response
  });
  return data;
};

const create = async (client: IClientInsert): Promise<IResponseAPI<IClient> | IResponseAPI<IClient>> => {

  const { data } = await Api.post('/otica/Client', client).catch(data => {
    return data.response;
  });

  return data;
};

const getComum = async (page = 1, filter = ''): Promise<IResponseAPI<IResponseAmount<IClient[]>> | IResponseAPI<IResponseAmount<IClient[]>>> => {

  const urlRelativa = `/otica/client/branch?name=${encodeURIComponent(filter)}&page=${page}&pageSize=${Environment.LIMITE_DE_LINHAS}`;
  const { data } = await Api.get(urlRelativa).catch(data => {
    return data.response;
  });

  return data;
};



const getById = async (id: number): Promise<IResponseAPI<any> | Error> => {
  try {
    const { data } = await Api.get(`/otica/otica/clientes/${id}`);

    if (data) {
      return data
    }

    return new Error('Erro ao consultar os registros.');
  } catch (error) {
    console.error(error);
    return new Error((error as { message: string }).message || 'Erro ao consultar os registros.');
  }
};



// const create = async (dados: Omit<IDetalheCliente, 'id'>): Promise<number | Error | undefined> => {
//   try {
//     const { data } = await Api.post<IDetalheCliente>('/clientes', dados);

//     if (data) {
//       return data.id
//     }

//     return new Error('Erro ao criar os registros.');
//   } catch (error) {
//     console.error(error);
//     return new Error((error as { message: string }).message || 'Erro ao criar os registros.');
//   }
// };

const updateById = async (id: number, dados: IClient): Promise<void | Error> => {
  try {
    await Api.put<IClient>(`/otica/clientes/${id}`, dados);
  } catch (error) {
    console.error(error);
    return new Error((error as { message: string }).message || 'Erro ao criar os registros.');
  }
};

const deleteById = async (id: number): Promise<void | Error> => {
  try {
    await Api.delete<IClient>(`/otica/clientes/${id}`);
  } catch (error) {
    console.error(error);
    return new Error((error as { message: string }).message || 'Erro ao criar os registros.');
  }
};


export const ClientesService = {
  get,
  create,
  getById,
  getComum,
  updateById,
  deleteById,
};