import { Box, CardContent, Typography, TextField, CardActions, Button, CircularProgress, Stepper, MobileStepper, useMediaQuery, Theme } from "@mui/material";
import { Dispatch, SetStateAction, useState } from "react";
import { Password } from "./login-left-register-password";
import { NameEmail } from "./login-left-register-name-email";
import Logo from "../../../shared/assets/images/OticaSystems_roxo.png"

interface ILeftContainerProps {
  isLoading: boolean;
  active: boolean;

  setConfirmPasswordError: Dispatch<SetStateAction<string>>;
  setConfirmPassword: Dispatch<SetStateAction<string>>;
  setPasswordError: Dispatch<SetStateAction<string>>;
  setEmailError: Dispatch<SetStateAction<string>>;
  setPassword: Dispatch<SetStateAction<string>>;
  setEmail: Dispatch<SetStateAction<string>>;

  confirmPasswordError: string;
  confirmPassword: string;
  passwordError: string;
  emailError: string;
  password: string;
  email: string;

  handlePasswordValidateSubmit: () => void;
  handleSubmitLogin: () => void;

  name: string;
  nameError: string;
  setNameError: Dispatch<SetStateAction<string>>;
  setName: Dispatch<SetStateAction<string>>;

  nameCompany: string;
  nameCompanyError: string;
  setNameCompanyError: Dispatch<SetStateAction<string>>;
  setNameCompany: Dispatch<SetStateAction<string>>;
}

export const LeftContainer: React.FC<ILeftContainerProps> =
  ({
    confirmPasswordError,
    confirmPassword,
    passwordError,
    emailError,
    isLoading,
    password,
    active,
    email,
    handlePasswordValidateSubmit,
    setConfirmPasswordError,
    setConfirmPassword,
    handleSubmitLogin,
    setPasswordError,
    setEmailError,
    setPassword,
    setEmail,

    name,
    nameError,
    setNameError,
    setName,
    nameCompany,
    nameCompanyError,
    setNameCompanyError,
    setNameCompany
  }) => {

    const [numberNavigation, setNumberNavigation] = useState(0);

    const handleAdvance = (prop: string) => {
      if (prop === "Password")
        setNumberNavigation(1);

      if (prop === "Email")
        setNumberNavigation(0);
      if (prop === "Confirm") {
        handlePasswordValidateSubmit();
      }
    };

    const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm')); //   sm = 600px  //  md = 900px  //  lg = 1200px

    var FormContainerSignin: React.CSSProperties = {

      top: '0',
      position: 'absolute',
      borderRadius: '15px 15px 15px 15px',
      transition: 'all 0.6s ease-in-out',
      ...(smDown && { left: '-13.5%' }),


      transform: 'translateX(100%)',
    };


    var FormContainerSignin_active: React.CSSProperties = {

      top: '0',
      position: 'absolute',
      borderRadius: '15px 15px 15px 15px',
      transition: 'all 0.6s ease-in-out',
    };


    return (
      <Box width='50%' height='100%'
        padding='10px' display='flex' alignItems='center' justifyContent='center' flexDirection='column'
        sx={active ? FormContainerSignin : FormContainerSignin_active}
        component='form'
        onSubmit={e => { e.preventDefault(); handleSubmitLogin() }}
      >
        {// Login
          !active && (
            <>

              <CardContent
                sx={{
                  width: smDown ? '200px' : '60%',      // para celular
                  ...(smDown && { margin: '0 0 0 17px' }) // para celular
                }}
              >
                <Box display='flex' flexDirection='column' gap={1} width='100%' justifyContent='center' alignItems='center'>
                  <img width={200} src={Logo} />
                  <Typography variant='h4' align='center' fontWeight={600}>Login</Typography>
                  <TextField
                    fullWidth
                    size="small"
                    type='email'
                    label='Email'
                    value={email}
                    disabled={isLoading}
                    error={!!emailError}
                   // helperText={!!emailError && "Email ou senha incorretos"}
                    onKeyDown={() => setEmailError('')}
                    onChange={e => setEmail(e.target.value)}
                  />

                  <TextField
                    fullWidth
                    size="small"
                    label='Senha'
                    type='password'
                    value={password}
                    disabled={isLoading}
                    error={!!passwordError}
                    helperText={!!passwordError && "Email ou senha incorretos"}
                    onKeyDown={() => setPasswordError('')}
                    onChange={e => setPassword(e.target.value)}
                  />

                  <Typography fontSize='14px' textAlign='center' component="a" href="/forgotPassword">
                    Esqueceu sua senha?
                  </Typography>

                </Box>
              </CardContent>

              <CardActions>
                <Box width='100%' display='flex' justifyContent='center'>

                  <Button
                    type="submit"
                    variant="contained"
                    disabled={isLoading}
                    endIcon={isLoading ? <CircularProgress variant='indeterminate' color='inherit' size={20} /> : undefined}
                  >
                    Entrar
                  </Button >

                </Box>
              </CardActions>
            </>
          )
        }
        {// Register
          active && (
            <>
              {numberNavigation === 1 &&
                <Password
                  confirmPasswordError={confirmPasswordError}
                  confirmPassword={confirmPassword}
                  passwordError={passwordError}
                  isLoading={isLoading}
                  password={password}
                  setConfirmPasswordError={setConfirmPasswordError}
                  setConfirmPassword={setConfirmPassword}
                  setPasswordError={setPasswordError}
                  handleAdvance={handleAdvance}
                  setPassword={setPassword}
                />
              }

              {numberNavigation === 0 &&
                <NameEmail
                  email={email}
                  name={name}
                  nameCompany={nameCompany}
                  emailError={emailError}
                  nameError={nameError}
                  nameCompanyError={nameCompanyError}
                  isLoading={isLoading}
                  handleAdvance={handleAdvance}
                  setEmailError={setEmailError}
                  setNameError={setNameError}
                  setNameCompanyError={setNameCompanyError}
                  setEmail={setEmail}
                  setName={setName}
                  setNameCompany={setNameCompany}
                />
              }
            </>
          )
        }
        <Box width='100%'
          display='flex'
          justifyContent='center'
          alignItems='center'
        >
          <MobileStepper
            activeStep={numberNavigation}
            position="static"
            steps={2}
            backButton={null}
            nextButton={null}
            sx={{
              display: active ? 'block' : 'none',
              backgroundColor: 'transparent',
              ...(!smDown && { margin: '0 40px 0 0' })
            }}
          />
        </Box>
      </Box>
    )
  }