import { CardContent, Box, Typography, TextField, CardActions, Button, CircularProgress, useMediaQuery, Theme } from "@mui/material";
import { Dispatch, SetStateAction, useState } from "react";
import styled from "styled-components";

import ImgCheck from '../../../shared/assets/icons/icon-check.png';
import ImgClose from '../../../shared/assets/icons/icon-close.png';
import Logo from "../../../shared/assets/images/OticaSystems_roxo.png"

interface ILeftPasswordProps {
  confirmPasswordError: string;
  confirmPassword: string;
  passwordError: string;
  isLoading: boolean;
  password: string;

  setConfirmPasswordError: Dispatch<SetStateAction<string>>;
  setConfirmPassword: Dispatch<SetStateAction<string>>;
  setPasswordError: Dispatch<SetStateAction<string>>;
  setPassword: Dispatch<SetStateAction<string>>;
  handleAdvance: (prop: string) => void;
}

export const Password: React.FC<ILeftPasswordProps> =
  ({
    confirmPasswordError,
    confirmPassword,
    passwordError,
    isLoading,
    password,
    setConfirmPasswordError,
    setConfirmPassword,
    setPasswordError,
    handleAdvance,
    setPassword,
  }) => {

    const [validadeInput, setValidateInput] = useState({
      case: false,
      number: false,
      character: false,
      length: false
    })


    const secureText = (password: string) => {

      const regexSpecialCharacter = /.*[!@#$%^&*(),.?":{}|<>].*/;
      const length = password.length >= 8;
      const regexUppercase = /[A-Z]/;
      const regexLowercase = /[a-z]/;
      const regexNumber = /[0-9]/;

      setValidateInput({
        case: regexUppercase.test(password) && regexLowercase.test(password),
        character: regexSpecialCharacter.test(password),
        number: regexNumber.test(password),
        length: length,
      })
    }

    const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm')); //   sm = 600px  //  md = 900px  //  lg = 1200px

    return (
      <>
        <CardContent>
          <Box display='flex' flexDirection='column' gap={1} width={250}
            sx={{
              ...(!smDown &&{margin: '0 50px 0 0'}), // Para Celular
              ...(smDown && {width: '170px'})       // Para Celular
            }}
          >

            <Box display='flex' flexDirection='column' justifyContent='center' alignItems='center' gap={1}
              sx={{
                ...(smDown && {flexDirection: 'column'}) // Para Celular
              }}
            >
              <img width={200} src={Logo} />
              <Typography variant='h4' align='center' fontWeight={600}>Cadastro</Typography>
            </Box>

            <TextField
              fullWidth
              label='Senha'
              type='password'
              size='small'
              value={password}
              disabled={isLoading}
              error={!!passwordError}
              //  helperText={ValitatePasswordErrorFunction()}
              onKeyDown={() => setPasswordError('')}
              onChange={e => { secureText(e.target.value); setPassword(e.target.value); }}
            />

            <TextField
              fullWidth
              label='Confirmar Senha'
              type='password'
              size='small'
              value={confirmPassword}
              disabled={isLoading}
              error={!!confirmPasswordError}
              helperText={confirmPasswordError}
              onKeyDown={() => setConfirmPasswordError('')}
              onChange={e => setConfirmPassword(e.target.value)}
            />

            <ValidatePasswordContent>
              <p style={{ fontSize: '14px', fontWeight: 'bold', marginBottom: 1 }}>
                Sua senha deve ter:
              </p>
              <div>
                <img src={validadeInput.length ? ImgCheck : ImgClose} />
                <p className="lines">8 caracteres</p>
              </div>

              <div>
                <img src={validadeInput.number ? ImgCheck : ImgClose} />
                <p className="lines">Números</p>
              </div>

              <div>
                <img src={validadeInput.case ? ImgCheck : ImgClose} />
                <p className="lines">Letra maiúscula e minúscula</p>
              </div>

              <div>
                <img src={validadeInput.character ? ImgCheck : ImgClose} />
                <p className="lines">Caracteres Especiáis</p>
              </div>
            </ValidatePasswordContent>



          </Box>
        </CardContent>

        <CardActions>
          <Box width='100%' display='flex' justifyContent='center' gap={4}

            sx={{              // Para Celular
              ...(smDown && 
                {
                  gap: 1, 
                  marginTop: -4
                }
              )
            }}                // Para Celular
          >

            <Button
              variant="outlined"
              disabled={isLoading}
              onClick={() => handleAdvance("Email")}
              endIcon={isLoading ? <CircularProgress variant='indeterminate' color='inherit' size={20} /> : undefined}
            >
              Voltar
            </Button >

            <Button
              variant="contained"
              disabled={isLoading}
              onClick={() => handleAdvance("Confirm")}
              endIcon={isLoading ? <CircularProgress variant='indeterminate' color='inherit' size={20} /> : undefined}

              size={smDown ? 'small' : 'medium'} // para Celular
            >
              Cadastrar
            </Button >

          </Box>
        </CardActions>
      </>
    )
  }

const ValidatePasswordContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;

  margin-bottom: 0px;

  div{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 5px;

    margin: -12px 0 -12px 0;
  }

  img{
    width: 11px;
    height: 11px;
  }

  .lines{
    font-size: 13px;
    color: #616161;
  }
`;