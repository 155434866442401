import { Typography, Chip, useTheme } from "@mui/material";

import FlexBox from "../../../shared/components/divs/flexBox";
import { IProdutoEstoque } from "../../../shared/services/api/estoques/EstoquesService";


interface IDetalhe1ContentProps {
  produto: IProdutoEstoque | undefined
};

export const Detalhe1Content: React.FC<IDetalhe1ContentProps> = ({ produto }) => {

  const theme = useTheme();


  return (
    <FlexBox row jcBetween>
      {/* <Typography>
          <Chip label="Nome:" /> <Chip label={produto?.name} variant="outlined" />
        </Typography> */}


      <FlexBox
        row aiCenter gap={10}
      >
        <FlexBox bgcolor={"#2e7bc2"} // bgcolor={theme.palette.primary.contrastText}
          borderRadius={2}
          height="110px"
          width="4px"

        />

        <FlexBox column gap={10}
        >
          <Typography>
            Quantidade: <Chip label={produto?.quantity} variant="outlined" size="small" />
          </Typography>

          <Typography>
            Custo: <Chip label={produto?.cost} variant="outlined" size="small" />
          </Typography>

          <Typography>
            Valor de venda: <Chip label={produto?.value} variant="outlined" size="small" />
          </Typography>
        </FlexBox>
      </FlexBox>





      <FlexBox
        row aiCenter gap={10}
      >
        <FlexBox column gap={10}
        >
          <Typography>
            Tipo: <Chip label="Capa" variant="outlined" size="small" />
          </Typography>

          <Typography>
            Grife: <Chip label="Raiban" variant="outlined" size="small" />
          </Typography>
        </FlexBox>

        <FlexBox bgcolor={"#2e7bc2"} // bgcolor={theme.palette.primary.contrastText}
          borderRadius={2}
          height="90px"
          width="4px"

        />
      </FlexBox>

    </FlexBox>
  );
};
