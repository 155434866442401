
export const Environment = {
  /**
   * Define a quantidade de linhas a ser carregada por padrão nas listagens
   */
  LIMITE_DE_LINHAS: 10,
  /**
   * Placeholder exibido nas inputs
   */
  INPUT_DE_BUSCA: 'Persquisar...',
  /**
   * Texto exibido quando nenhum registro é encontrado em uma listagem
   */
  LISTAGEM_VAZIA: 'Nenhum registro encontrado.',
  /**
   * Url base de consultado dos dados dessa aplicação
   */
  URL_BASE: 'https://api.oticasystems.com.br',
    /**
   * Key de criptografia do LocalStorage
   */
  PASSWORD_CRIPTO: '1ZxLX!!LCzqB7I2T*c4iDRpgjariOe6wim9gq9^T3wWZ0hq4yWcf',
};

// process.env.PUBLIC_URL

export const Env = {
  /**
   * Border Radius Global
   */
  BD_RADIUS: 10,
  /**
   * Global display Flex, com flex-direction: column
   */
  FLEX_COLUMN: { display: 'flex', flexDirection: 'column' },
  /**
   * Global display Flex, com flex-direction: row
   */
  FLEX_ROW: { display: 'flex', flexDirection: 'row' },
};