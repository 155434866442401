import { Dispatch, SetStateAction } from "react";
import FlexBox from "../../../shared/components/divs/flexBox";
import { IProdutoEstoque } from "../../../shared/services/api/estoques/EstoquesService";
import { CardProducts } from "../components/Card-Products";
import { SetURLSearchParams } from "react-router-dom";
import { Environment } from "../../../shared/environment";
import { LinearProgress, Pagination, TableCell, TableRow, Typography } from "@mui/material";
import { CardSkeleton } from "../components/Card-Skeleton";

interface CardsContentProps {
  rows: IProdutoEstoque[];
  isLoading: boolean;
  totalCount: number;
  setRows: Dispatch<SetStateAction<IProdutoEstoque[]>>
  pagina: number;
  busca: string;
  setSearchParams: SetURLSearchParams;
}



export const CardsContent: React.FC<CardsContentProps> = ({ rows, setRows, isLoading, totalCount, setSearchParams, pagina, busca }) => {


  return <>

    <FlexBox column gap={10} marginTop="20px">
      {                                           //   Carregando
        isLoading && (<>
          <LinearProgress variant='indeterminate' 
            sx={{margin: '-35px 0 35px 0'}}
          />

          <CardSkeleton />
          <CardSkeleton />
          <CardSkeleton />
          <CardSkeleton />
        </>
        )
      }



      {
        !isLoading && rows?.map(row => (        // .map()   Produtos

          <CardProducts
            key={row.id}
            produto={row}
            setRows={setRows}
          />
        ))
      }


      {totalCount === 0 && !isLoading && (       //   Lista vazia
        <caption>{Environment.LISTAGEM_VAZIA}</caption>
      )}
    </FlexBox>




    {
      (totalCount > 0 && totalCount > Environment.LIMITE_DE_LINHAS) && (   // Paginação
        <FlexBox marginTop="30px" >
          <TableRow>
            <TableCell colSpan={3}>
              <Pagination
                page={pagina}
                count={Math.ceil(totalCount / Environment.LIMITE_DE_LINHAS)}
                onChange={(_, newPage) => setSearchParams({ busca, pagina: newPage.toString() }, { replace: true })}
              />
            </TableCell>
          </TableRow>
        </FlexBox>
      )
    }

    <FlexBox fullWidth jcStart>
      <Typography
        variant="h6"
        sx={{ fontSize: '14px', marginTop: '20px' }}
      >
        Foram encontrados um total de {totalCount} clientes
      </Typography>
    </FlexBox>

  </>
};
