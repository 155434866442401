import { useEffect, useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { useDebounce } from "../../shared/hooks";
import { TabelaEstoque } from "./sections/TabelaEstoque";
import { LayoutBaseDePagina } from "../../shared/layouts";
import { FerramentasDaListagem } from "../../shared/components";
import { ModalNovoProduto } from "./components/Modal-NovoProduto";
import { IProdutoEstoque, EstoquesService } from "../../shared/services/api/estoques/EstoquesService";
import { CardsContent } from "./sections/CardsContent";
import { Theme, Typography, useMediaQuery } from "@mui/material";
import FlexBox from "../../shared/components/divs/flexBox";


export const ListagemDeEstoques: React.FC = () => {

  const [searchParams, setSearchParams] = useSearchParams();
  const { debounce } = useDebounce(800, true);

  const [isOpen, setIsOpen] = useState(false);

  const [rows, setRows] = useState<IProdutoEstoque[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [totalCount, setTotalCount] = useState(0);

  const busca = useMemo(() => {
    return searchParams.get('busca') || '';
  }, [searchParams]);

  const pagina = useMemo(() => {
    return Number(searchParams.get('pagina') || '1');
  }, [searchParams]);



  useEffect(() => {
    setIsLoading(true);

    debounce(() => {
      EstoquesService.getAll(pagina, busca)
        .then((result) => {
          setIsLoading(false);

          if (result instanceof Error) {
            alert(result.message);
          }
          else if (result.data?.amount !== undefined && result.data.data !== undefined) {
            setTotalCount(result.data.amount);
            setRows(result.data.data);
          }
        }
        );
    });
  }, [busca, pagina, debounce]);

  function handleNewProduct() {
    setIsOpen(true);
  };

  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  return (
    <LayoutBaseDePagina
      titulo="Estoque"
      barraDeFerramentas={
        <FerramentasDaListagem
          mostrarInputBusca
          textoDaBusca={busca}
          textoBotaoNovo="Novo Produto"
          aoClicarEmNovo={handleNewProduct}
          aoMudarTextDeBusca={texto => setSearchParams({ busca: texto, pagina: '1' }, { replace: true })}
        />
      }
    >

      {
        !smDown && (     // Para Computador
          <TabelaEstoque
            rows={rows}
            setRows={setRows}
            isLoading={isLoading}
            totalCount={totalCount}
            setSearchParams={setSearchParams}
            pagina={pagina}
            busca={busca}
          />
        )
      }

      {
        smDown && (       // Para Celular
          <CardsContent
            rows={rows}
            setRows={setRows}
            isLoading={isLoading}
            totalCount={totalCount}
            setSearchParams={setSearchParams}
            pagina={pagina}
            busca={busca}
          />
        )
      }

      <ModalNovoProduto
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />

    </LayoutBaseDePagina>
  )
}