import { useState } from "react";
import { Box, Button, CircularProgress, Paper, TextField, Theme, Typography, useMediaQuery } from "@mui/material";
import * as yup from 'yup';

import { useAuthContext } from "../../shared/contexts";
import { LeftContainer, RightContainer } from "./components";
import { AuthService } from "../../shared/services/api/auth/AuthService";
import FlexBox from "../../shared/components/divs/flexBox";
import Logo from "../../shared/assets/images/OticaSystems_roxo.png"



const loginSchema = yup.object().shape({
  email: yup.string().email().required(),
  password: yup.string().required().min(8).matches(/.*[!@#$%^&*(),.?":{}|<>].*/, 'A senha deve conter pelo menos um caractere especial'),
  // password: yup.string().required().min(8).matches(/.*[!@#$%^&*(),.?":{}|<>].*/, 'A senha deve conter pelo menos um caractere especial'),
  // confirmPassword: yup.string().required().oneOf([yup.ref('password'), null], 'As senhas devem coincidir'),
})

const registerSchema = yup.object().shape({
  name: yup.string().required(),
  nameCompany: yup.string().required(),
  email: yup.string().email().required()
})

export const Login: React.FC = () => {
  const { login } = useAuthContext();

  // Carregamento States
  const [isLoading, setIsLoading] = useState(false);
  // Error States
  const [confirmPasswordError, setConfirmPasswordError] = useState('As senhas devem coincidir');
  const [nameCompanyError, setNameCompanyError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [nameError, setNameError] = useState('');
  // Login
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  // Register
  const [confirmPassword, setConfirmPassword] = useState('');
  const [nameCompany, setNameCompany] = useState('');
  const [name, setName] = useState('');




  // validação das div's (design CSS)
  const [active, setActive] = useState(false);




  const handleResetStates = () => {

    setActive(!active);
    setConfirmPasswordError('');
    setConfirmPassword('');
    setPasswordError('');
    setPassword('');
    setEmailError('');
    setEmail('');
  }

  const handleSubmitLogin = () => {
    setIsLoading(true);
    loginSchema
      .validate({ email, password }, { abortEarly: false })
      .then(dadosValidados => {
        login(dadosValidados.email, dadosValidados.password)
          .then((data) => {
            setIsLoading(false);
            if (data.errors?.length > 0) {
              setPasswordError(data.errors[0])
            }
          });
      })
      .catch((errors: yup.ValidationError) => {
        setIsLoading(false);

        errors.inner.forEach(error => {
          if (error.path === 'email') {
            setEmailError(error.message);
          } else if (error.path === 'password') {
            setPasswordError(error.message);
          }
        });
      });
  }

  const handleSubmitRegister = () => {
    setIsLoading(true);

    registerSchema
      .validate({ name, email, nameCompany }, { abortEarly: false })
      .then(dadosValidados => {
        AuthService.register(dadosValidados.name, dadosValidados.email, dadosValidados.nameCompany, password)
          .then((result) => {
            setIsLoading(false);
            alert("Conta criada com sucesso! Por favor confirmar seus email.");
            setActive(false);
          });
      })
      .catch((errors: yup.ValidationError) => {
        setIsLoading(false);

        errors.inner.forEach(error => {
          if (error.path === 'name') {
            setNameError(error.message);
          } else if (error.path === 'email') {
            setEmailError(error.message);
          } else if (error.path === 'nameCompany') {
            setNameCompanyError(error.message);
          }
        });
      });
  }

  const handlePasswordValidateSubmit = () => {

    confirmPassword !== password && setConfirmPasswordError('As senhas devem coincidir');
    confirmPassword === '' && setConfirmPasswordError('Compo obrigatório');
    password === '' && setPasswordError('Compo obrigatório');
    setIsLoading(true);
    if (passwordError === '' && confirmPasswordError === '')
      handleSubmitRegister();
    setIsLoading(false);

  }

  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));


  if (!smDown)  // Tela para PC
    return (
      <Box // Body
        width='100vw' height='100vh'
        display='flex' alignItems='center' justifyContent='center'
        sx={{ background: 'linear-gradient(to right, #e2e2e2, #c9d6ff)' }}
      >

        <Box  // Container Father(pai)
          component={Paper}
          elevation={18}
          borderRadius={7}
          sx={{ boxShadow: '0 5px 15px rgba(0, 0, 0, 0.35)', width: '90%', maxWidth: '900px', transition: 'all 0.6s ease-in-out' }}
          display='flex' flexDirection='row'
          height='570px'

          position='relative'
        >

          <LeftContainer // Login e Register
            confirmPasswordError={confirmPasswordError}
            confirmPassword={confirmPassword}
            passwordError={passwordError}
            emailError={emailError}
            isLoading={isLoading}
            nameError={nameError}
            nameCompanyError={nameCompanyError}
            password={password}
            active={active}
            email={email}
            name={name}
            nameCompany={nameCompany}
            handlePasswordValidateSubmit={handlePasswordValidateSubmit}
            setConfirmPasswordError={setConfirmPasswordError}
            setConfirmPassword={setConfirmPassword}
            handleSubmitLogin={handleSubmitLogin}
            setPasswordError={setPasswordError}
            setEmailError={setEmailError}
            setNameError={setNameError}
            setNameCompanyError={setNameCompanyError}
            setPassword={setPassword}
            setEmail={setEmail}
            setName={setName}
            setNameCompany={setNameCompany}

          />


          <RightContainer // Alternar "Página"
            active={active}
            handleResetStates={handleResetStates}
          />

        </Box>
      </Box>
    )
  else         // Tela para CELULAR
    return (
      <FlexBox
        jcCenter aiCenter height="100vh" column gap={20}
        paddingX={4}
        sx={{ background: "linear-gradient(to right, #ead4ec92, #c9d6ff)" }}
        component="form"
        onSubmit={e => { e.preventDefault(); handleSubmitLogin() }}
      >
        <img style={{marginBottom: 10}} width={200} src={Logo} />

        <TextField
          fullWidth
          type='email'
          label='Email'
          size="small"
          value={email}
          disabled={isLoading}
          error={!!emailError}
          helperText={emailError}
          onKeyDown={() => setEmailError('')}
          onChange={e => setEmail(e.target.value)}
        />
        <TextField
          fullWidth
          label='Senha'
          type='password'
          size="small"
          value={password}
          disabled={isLoading}
          error={!!passwordError}
          helperText={!!passwordError && "Email ou senha incorretos"}
          onKeyDown={() => setPasswordError('')}
          onChange={e => setPassword(e.target.value)}
        />
        <Typography fontSize='14px' textAlign='center' component="a" href="/forgotPassword">
          Esqueci minha senha
        </Typography>

        <Button
          type="submit"
          variant="contained"
          disabled={isLoading}
          endIcon={isLoading ? <CircularProgress variant='indeterminate' color='inherit' size={20} /> : undefined}
          size="large"
        >
          Entrar
        </Button >

        <Typography fontSize='14px' textAlign='center' marginTop={0}>
          Não tem uma conta? <Typography component='a' href="/forgotPassword">Cadastre aqui</Typography>
        </Typography>

      </FlexBox>
    )
}