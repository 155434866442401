import { CardHeader, Skeleton } from "@mui/material";

export const CardSkeleton = () => {
  return (
    <CardHeader sx={{marginTop: '-30px'}}
      avatar={
        <Skeleton animation="wave" variant="rounded" width={15} height={85} />
      }
      title={
        <Skeleton animation="wave" height={17} width="80%" style={{ marginBottom: 6 }}
        />
      }
      subheader={
        <>
          <Skeleton animation="wave" height={17} width="40%" sx={{marginTop: '10px'}} />
          <Skeleton animation="wave" height={22} width="20%" sx={{marginTop: '10px'}}/>
        </>
      }

      action={
        <Skeleton animation="wave" variant="rounded" width={11} height={30} sx={{marginTop: '10px'}} />
      } 
      >

    </CardHeader>
  )
};
