import { useEffect, useState } from "react";
import { Box, Card, CardContent, CircularProgress, Grid, Theme, Typography, useMediaQuery } from "@mui/material";

import { OverviewSales, OverviewTotalCustomers, OverviewBudget, OverviewTotalProfit } from "./sections";
import { LayoutBaseDePagina } from "../../shared/layouts";
import FlexBox from "../../shared/components/divs/flexBox";
import { useAuthContext } from "../../shared/contexts";

export const Dashboard = () => {
  const [isLoadingCidades, setIsLoadingCidades] = useState(true);
  const [totalCountCidades, setTotalCountCidades] = useState(0);

  const [isLoadingPessoas, setIsLoadingPessoas] = useState(true);
  const [totalCountPessoas, setTotalCountPessoas] = useState(0);

  const { roles } = useAuthContext();

  useEffect(() => {
    setIsLoadingCidades(true);
    setIsLoadingPessoas(true);
  }, []);


  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));


  return (
    <LayoutBaseDePagina
      titulo="Página inicial"
    >
      {
        smDown && (
          <FlexBox fullWidth jcEnd height="20px"
            margin="-30px 0px 10px 0px"
          >
            <Typography>
              {roles.filter(role => role.isSelected === true)[0].branch.name}
            </Typography>
          </FlexBox>
        )
      }
      <Box width='100%' display='flex' >
        <Grid container>

          <Grid item container spacing={4}
          // bgcolor='GrayText'
          >
            <Grid item
              xs={12}
              sm={6}
              lg={4}
            >
              <OverviewBudget
                difference={12}
                positive
                sx={{ height: '100%' }}
                value="$24k"
              />
            </Grid>
            <Grid item
              xs={12}
              sm={6}
              lg={4}
            >
              <OverviewTotalCustomers
                difference={16}
                positive={false}
                sx={{ height: '100%' }}
                value="1.6k"
              />
            </Grid>
            <Grid item
              xs={12}
              sm={6}
              lg={4}
            >
              <OverviewTotalProfit
                sx={{ height: '100%' }}
                value="$15k"
              />
            </Grid>


            <Grid item
              xs={12} // tela pequena
              lg={12} // tela grande
            >
              <OverviewSales
                chartSeries={[
                  {
                    name: 'Este ano',
                    data: [18, 16, 5, 8, 3, 14, 14, 16, 17, 19, 18, 20]
                  },
                  {
                    name: 'Ano passado',
                    data: [12, 11, 4, 6, 2, 9, 9, 10, 11, 12, 13, 13]
                  }
                ]}
                sx={{ height: '100%' }}
              />
            </Grid>


{/* 
            <Grid item xs={12} sm={12} md={6} lg={4} xl={4}
            // bgcolor='#af6262f3'
            >

              <Box margin={0}>
                <Card sx={{ borderRadius: `${Env.BD_RADIUS}` }}>
                  <CardContent sx={{ borderRadius: `${Env.BD_RADIUS}` }}>
                    <Typography variant="h5" align="center">
                      Total de Pessoas
                    </Typography>

                    <Box padding={6} display='flex' justifyContent='center' alignItems='center'>
                      {!isLoadingPessoas && (
                        <Typography variant="h1">
                          {totalCountPessoas}
                        </Typography>
                      )}
                      {isLoadingPessoas && (
                        <CircularProgress size={90} />
                      )}
                    </Box>
                  </CardContent>
                </Card>
              </Box>

            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={4} xl={4}
            // bgcolor='#73af62f3'
            >

              <Box margin={0}>
                <Card>
                  <CardContent>
                    <Typography variant="h5" align="center">
                      Total de Cidades
                    </Typography>

                    <Box padding={6} display='flex' justifyContent='center' alignItems='center'>
                      {!isLoadingCidades && (
                        <Typography variant="h1">
                          {totalCountCidades}
                        </Typography>
                      )}
                      {isLoadingCidades && (
                        <CircularProgress size={90} />
                      )}
                    </Box>
                  </CardContent>
                </Card>
              </Box>

            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={4} xl={4}
            // bgcolor='#af6262f3'
            >

              <Box margin={0}>
                <Card sx={{ borderRadius: `${Env.BD_RADIUS}` }}>
                  <CardContent sx={{ borderRadius: `${Env.BD_RADIUS}` }}>
                    <Typography variant="h5" align="center">
                      Total de Pessoas
                    </Typography>

                    <Box padding={6} display='flex' justifyContent='center' alignItems='center'>
                      {!isLoadingPessoas && (
                        <Typography variant="h1">
                          {totalCountPessoas}
                        </Typography>
                      )}
                      {isLoadingPessoas && (
                        <CircularProgress size={90} />
                      )}
                    </Box>
                  </CardContent>
                </Card>
              </Box>

            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={4} xl={4}
            // bgcolor='#73af62f3'
            >

              <Box margin={0}>
                <Card>
                  <CardContent>
                    <Typography variant="h5" align="center">
                      Total de Cidades
                    </Typography>

                    <Box padding={6} display='flex' justifyContent='center' alignItems='center'>
                      {!isLoadingCidades && (
                        <Typography variant="h1">
                          {totalCountCidades}
                        </Typography>
                      )}
                      {isLoadingCidades && (
                        <CircularProgress size={90} />
                      )}
                    </Box>
                  </CardContent>
                </Card>
              </Box>

            </Grid> */}

          </Grid>

        </Grid>
      </Box>
    </LayoutBaseDePagina>
  );
};