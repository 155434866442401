import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { EstoquesService, IProdutoEstoque } from "../../shared/services/api/estoques/EstoquesService";
import { FerramentasDeDetalhe } from "../../shared/components";
import { LayoutBaseDePagina } from "../../shared/layouts";
import FlexBox from "../../shared/components/divs/flexBox";
import { Chip, Typography } from "@mui/material";
import { Detalhe1Content } from "./sections";



export const DetalheDeProduto: React.FC = () => {

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const [isLoading, setIsLoading] = useState(false);
  const [produto, setProduto] = useState<IProdutoEstoque>();

  useEffect(() => {
    setIsLoading(true);

    EstoquesService.getById(Number(id)).then(
      result => {
        if (result.success && result.data !== undefined) {
          setProduto(result.data)
        } else {
          alert(result.errors + result.message);
        }
      }
    );

  }, [id]);



  return (
    <LayoutBaseDePagina
      titulo={produto?.name || ''}
    // barraDeFerramentas={
    //   <FerramentasDeDetalhe
    //     textoBotaoNovo="Nova"
    //     mostrarBotaoSalvarEFechar
    //     mostrarBotaoNovo={id !== 'nova'}
    //     mostrarBotaoApagar={id !== 'nova'}

    //     aoClicarEmSalvar={save}
    //     aoClicarEmSalvarEFechar={saveAndClose}
    //     aoClicarEmVoltar={() => navigate('/estoques/')}
    //     aoClicarEmApagar={() => handleDelete(Number(id))}
    //     aoClicarEmNovo={() => navigate('/estoques/detalhe/nova')}

    //   //() => formRef.current?.submitForm()
    //   />
    // }
    >
      
      <Detalhe1Content 
        produto={produto}
      />

    </LayoutBaseDePagina>
  );
};