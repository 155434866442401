import { Card, Icon, IconButton, Menu, MenuItem, Typography } from "@mui/material";
import FlexBox from "../../../shared/components/divs/flexBox";
import { EstoquesService, IProdutoEstoque } from "../../../shared/services/api/estoques/EstoquesService";
import { useNavigate } from "react-router-dom";
import { Dispatch, SetStateAction, useState } from "react";
import { ModalEditarProduto } from "./Modal-EditarProduto";

interface CardProductsProps {
  produto: IProdutoEstoque;
  setRows: Dispatch<SetStateAction<IProdutoEstoque[]>>
}

export const CardProducts: React.FC<CardProductsProps> = ({ produto, setRows }) => {

  const navigate = useNavigate();

  const [isOpenEdit, setIsOpenEdit] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  function handleDelete(id: number) {

    let message = "Deseja apagar este produto do estoque ?";
    if (window.confirm(message)) {

      EstoquesService.deleteById(id).then(
        result => {
          if (result.success) {
            setRows(oldRow => oldRow.filter(row => row.id !== id));
          } else {
            alert(result.errors + result.message);
          }
        }
      );
    };
  };

  function handleClick(event: React.MouseEvent<HTMLElement>) {
    setAnchorEl(event.currentTarget);
  };



  return (
    <FlexBox component={Card}
      borderRadius={2}
      padding={1}
      minHeight={80}
      //boxShadow="0px 0px 35px -25px rgba(0,0,0,0.75)"
      boxShadow="15px 15px 35px -26px rgba(0, 0, 0, 0.437)"
      row
    >

      <FlexBox width="15px" marginRight="15px"
        jcStart aiCenter column borderRadius={2} bgcolor={"#4178ac7f"}
        onClick={() => navigate(`/produto/detalhe/${produto.id}`)}
      >
      </FlexBox>

      <FlexBox flex={1} column gap={1} marginRight="5px" padding="0 0 0 8px"
        onClick={() => navigate(`/produto/detalhe/${produto.id}`)}
      >
        <Typography fontWeight={600}>{produto.name}</Typography>

        <FlexBox row aiEnd gap={4}>
          <Typography fontSize={11} fontWeight={400}>Quantidade:</Typography>
          <Typography fontWeight={700} marginBottom="-3px">{produto.quantity}</Typography>
        </FlexBox>

        <FlexBox row flex={1} jcBetween >
          <FlexBox column jcEnd>
            <Typography color='#40b31c'>R$ {produto.value}</Typography>
          </FlexBox>
        </FlexBox>

      </FlexBox>

      <FlexBox width="40px" column aiStart jcStart jcEnd >
        <IconButton size='medium'
          onClick={handleClick}
        >
          <Icon>more_vert_sharp</Icon>
        </IconButton>
        <Menu
          id="demo-positioned-menu"
          aria-labelledby="demo-positioned-button"
          anchorEl={anchorEl}
          open={open}
          onClose={() => setAnchorEl(null)}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <MenuItem onClick={() => { setIsOpenEdit(true); setAnchorEl(null) }}>
            <Icon>edit</Icon>Editar
          </MenuItem>
          <MenuItem onClick={() => { handleDelete(produto.id); setAnchorEl(null) }}>
            <Icon>delete</Icon>Excluir
          </MenuItem>
        </Menu>

      </FlexBox>


      <ModalEditarProduto
        isOpen={isOpenEdit}
        setIsOpenEdit={setIsOpenEdit}
        produto={produto}
      />

    </FlexBox>
  )
};
