import React, { useEffect, useState } from 'react';
import { Typography, Button, Icon } from '@mui/material';

import { SectionCellphone } from './Modal-NovoCliente-3-contato';
import FlexBox from '../../../../../shared/components/divs/flexBox';
import { IOptionNewClient } from '../Modal-NovoCLiente2';
import { IContactInsert } from '../../../../../shared/models/Clients/contact';
import { NewClientStorage } from '../../../../../shared/services/storage/newClientStorage';

interface SectionContactProps {
  position: number;
  optionsNew: IOptionNewClient[];
  handleToBack(): void
  handleAdvance: () => void;
  handleSubmit(): void
  contacts: IContactInsert[];
  setContacts: React.Dispatch<React.SetStateAction<IContactInsert[]>>;
}

export const SectionContact: React.FC<SectionContactProps> = (props) => {
  const { handleSubmit, handleAdvance, handleToBack, position, optionsNew, contacts, setContacts } = props;
  
  useEffect(()=>{
    getContacts()
  },[])

  const getContacts = () => {
    const storageClient = new NewClientStorage();
    const contactsStorage = storageClient.getContacts();
    if (!!contactsStorage) {
      console.log(contactsStorage)
      setContacts(contactsStorage);
    }
  }
  const saveContacts = () =>{
    const storageClient = new NewClientStorage();
    storageClient.saveContacts(contacts);
  }

  const back = () =>{
    handleToBack()
    saveContacts()
  }
  const next = () =>{
    handleAdvance()
    saveContacts()
  }
  return (
    <FlexBox column>
      <FlexBox>
        <SectionCellphone
          rowsCellphones={contacts}
          setRowsCellphones={setContacts}
          errorCellphoneName={false}
        />
      </FlexBox>


      <FlexBox row jcBetween>
        <Button variant="text" startIcon={<Icon>fast_rewind_rounded</Icon>} onClick={() => back()}>
          Voltar
        </Button>
        {
          (optionsNew.filter(x => x.active).length > position + 1) && (
            <Button variant="outlined" endIcon={<Icon>forward_rounded</Icon>} onClick={() => next()}>
              Avançar
            </Button>

          )
        }
        {
          optionsNew.filter(x => x.active).length == position + 1 && (
            <Button variant="contained" endIcon={<Icon>save_rounded</Icon>} onClick={handleSubmit}>
              Salvar
            </Button>
          )
        }
      </FlexBox>
    </FlexBox>
  )
}
