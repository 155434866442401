import { Box, Typography, Paper, Button, Icon, useMediaQuery, Theme, Stack } from '@mui/material';
import { CardCellphone } from '../../../components/CardCellphone';
import { Env } from '../../../../../shared/environment';
import { IContactInsert } from '../../../../../shared/models/Clients/contact';

interface SectionCellphoneProps {
  rowsCellphones: IContactInsert[];
  setRowsCellphones: React.Dispatch<React.SetStateAction<IContactInsert[]>>;
  errorCellphoneName: boolean;
}

export const SectionCellphone: React.FC<SectionCellphoneProps> = ({ 
  rowsCellphones, 
  setRowsCellphones, 
  errorCellphoneName 
}) => {

  const handleAddCellphone = () => {
    let noCell: IContactInsert = { value: '', type: 0, isMain: false }
    setRowsCellphones(prevListTel => [...prevListTel, noCell]);
  }

  const handleDeleteCellphone = (indice: number) => {
    if(rowsCellphones.length > 1) {
      setRowsCellphones(prevListTel => prevListTel.filter((_, index) => index !== indice));
    }
    else {
      return;
    };
  };

  const lgDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg')); //   sm = 600px  //  md = 900px  //  lg = 1200px

  return (
    <Box // content inferior
      width='100%' minHeight='200px'
      sx={Env.FLEX_COLUMN} gap={1} boxSizing='border-box'
    >
      <Stack width='100%' height='min-content' sx={ lgDown ? Env.FLEX_COLUMN : Env.FLEX_ROW} gap={5} //bgcolor='#3333334f'
      >
        <Paper component={Box} width='100%' boxSizing='border-box' elevation={0} // Telefone Container
        >
          <Box
            width='100%'
            minHeight='100px'
            paddingBottom='15px'
            boxSizing='border-box'
            display='flex'
            alignItems='center'
            flexDirection='column'
            gap='1rem'
            borderRadius='20px'
          >
            <Box
              width='100%'
              minHeight='50px'
              borderRadius='10px'
              display='flex'
              justifyContent='space-between'
              alignItems='center'
              flexDirection='row'
              gap='0rem'
            >
              <Typography>Contatos</Typography>
              <Button
                startIcon={<Icon>add</Icon>}
                onClick={handleAddCellphone}
                variant="contained"
              >
                Contato
              </Button>
            </Box>
            <Box
              width='100%'
              minHeight='100px'
              boxSizing='border-box'
              borderRadius='10px'
              display='flex'
              justifyContent='center'
              alignItems='center'
              flexDirection='column'
              gap='1rem'
            >
              {rowsCellphones.map((_, indice) => (
                <CardCellphone
                  handleDeleteCellphone={handleDeleteCellphone}
                  errorCellphoneName={errorCellphoneName}
                  setRowsCellphones={setRowsCellphones}
                  rowsCellphones={rowsCellphones}
                  indice={indice}
                />
              ))}
            </Box>
          </Box>
        </Paper>
      </Stack>
    </Box>
  )
}