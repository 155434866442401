import { CardContent, Box, Typography, TextField, CardActions, Button, CircularProgress, useMediaQuery, Theme } from "@mui/material";
import { Dispatch, SetStateAction, useState } from "react";
import Logo from "../../../shared/assets/images/OticaSystems_roxo.png"

interface ILeftPasswordProps {
  isLoading: boolean;
  emailError: string;
  nameError: string;
  nameCompanyError: string;
  email: string;
  name: string;
  nameCompany: string;

  setEmailError: Dispatch<SetStateAction<string>>;
  setNameError: Dispatch<SetStateAction<string>>;
  setNameCompanyError: Dispatch<SetStateAction<string>>;
  setEmail: Dispatch<SetStateAction<string>>;
  setName: Dispatch<SetStateAction<string>>;
  setNameCompany: Dispatch<SetStateAction<string>>;

  handleAdvance: (prop: string) => void;
}

export const NameEmail: React.FC<ILeftPasswordProps> =
  ({
    email,
    name,
    nameCompany,
    emailError,
    nameError,
    nameCompanyError,
    isLoading,
    handleAdvance,
    setEmailError,
    setNameError,
    setNameCompanyError,
    setEmail,
    setName,
    setNameCompany,
  }) => {

    const handleNext = () => {
      handleAdvance("Password");
    }

    const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm')); //   sm = 600px  //  md = 900px  //  lg = 1200px

    return (
      <>
        <CardContent>
          <Box display='flex' flexDirection='column' gap={1}
            width={250}
            sx={{
              ...(!smDown && { margin: '0 50px 0 0' }), // Para Celular
              ...(smDown && { width: '170px' })       // Para Celular
            }}
          >
            <Box display='flex' flexDirection='column' justifyContent='center' alignItems='center' gap={1}
              sx={{
                ...(smDown && { flexDirection: 'column' }) // Para Celular
              }} width='100%'
            >
              <img width={200} src={Logo} />
              <Typography variant='h4' align='center' fontWeight={600}>Cadastro</Typography>
            </Box>

            <TextField
              fullWidth
              type='text'
              size='small'
              value={name}
              label='Seu nome'
              error={!!nameError}
              disabled={isLoading}
              helperText={nameError}
              onKeyDown={() => setNameError('')}
              onChange={e => setName(e.target.value)}
            />

            <TextField
              fullWidth
              type='text'
              size='small'
              value={nameCompany}
              error={!!nameCompanyError}
              disabled={isLoading}
              label='Nome da Ótica'
              //helperText={nameCompanyError}
              onKeyDown={() => setNameCompanyError('')}
              onChange={e => setNameCompany(e.target.value)}
            />

            <TextField
              fullWidth
              type='email'
              size='small'
              label='Email'
              value={email}
              disabled={isLoading}
              error={!!emailError}
              helperText={emailError}
              onKeyDown={() => setEmailError('')}
              onChange={e => setEmail(e.target.value)}
            />

          </Box>
        </CardContent>

        <CardActions>
          <Box width='100%' display='flex' justifyContent='center'
            sx={{
              ...(!smDown && { margin: '0 40px 0 0' })
            }}
          >

            <Button
              variant="contained"
              disabled={isLoading}
              onClick={handleNext}
              endIcon={isLoading ? <CircularProgress variant='indeterminate' color='inherit' size={20} /> : undefined}
            >
              Avançar
            </Button >

          </Box>
        </CardActions>
      </>
    )
  }