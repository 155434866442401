import { Icon, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { useMatch, useNavigate, useResolvedPath } from "react-router-dom";
import { Env } from "../../../environment";

interface IListItemLinkProps {
  to: string;
  icon: string;
  label: string;
  onClick: () => void | undefined;
}

export const ListItemLink: React.FC<IListItemLinkProps> = ({ to, icon, label, onClick }) => {
  const navigate = useNavigate();

  const resolvedPath = useResolvedPath(to);
  const match = useMatch({ path: resolvedPath.pathname, end: false });

  const handleClick = () => {
    navigate(to);
    onClick?.();
  }


  return (
    <ListItemButton selected={!!match} onClick={handleClick}
      sx={{ borderRadius: Env.BD_RADIUS - 6 }}
    //sx={{ '&.Mui-selected': { backgroundColor: eduFlex.activeColor }, borderRadius: Env.BD_RADIUS - 6 }}
    >
      <ListItemIcon>
        <Icon sx={{ '&.Mui-selected': { color: '#fff' } }}>
          {icon}
        </Icon>
      </ListItemIcon>
      <ListItemText primary={label} />
    </ListItemButton>
  );
};