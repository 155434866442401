import { Box, Button, Paper, Typography, useMediaQuery } from "@mui/material";
import FlexBox from "../../shared/components/divs/flexBox";
//import ImgFundo from "../../assets/fundo-fumaca.png";
import ConfirmEmail from '../../shared/assets/icons/Confirm-Email-Icon.png';
import { useLocation, useNavigate } from "react-router-dom";
import { AuthService } from "../../shared/services/api/auth/AuthService";
import { useEffect, useState } from "react";


export const ConfirmedEmail: React.FC = () => {
  const [message, setMessage] = useState('Carregando..');
  const [status, setStatus] = useState(false);
  useEffect(() => {
    confirmarEmail()
  }, [])

  const nav = useNavigate();

  const smDown = useMediaQuery('(max-width:600px)');

  const path = useLocation()
  const hash = path.search.replace("?hash=", "")

  const confirmarEmail = () => {
    AuthService.confirmEmail(hash).then((confirmarEmail) => {
      setStatus(confirmarEmail.success);
      if (confirmarEmail.success)
        setMessage('Email confirmado com sucesso!');

      setMessage(confirmarEmail.errors[0]);
    });
  }

  return (
    <>
      <Box // Body
        width='100vw' height='100vh'
        display='flex' alignItems='center' justifyContent='center'
        sx={{ background: 'linear-gradient(to right, #e2e2e2, #c9d6ff)', }}
      >
        <Box  // Container Father(pai)
          component={Paper}
          elevation={18}
          borderRadius={7}
          sx={{
            boxShadow: '0 5px 15px rgba(0, 0, 0, 0.35)', width: '90%', maxWidth: '900px', transition: 'all 0.6s ease-in-out',
            // backgroundImage: `url(${ImgFundo})`,
            // backgroundRepeat: "no-repeat",
            // backgroundSize: smDown ? '250px' : '330px',
            // backgroundPositionX: '',
          }}
          display='flex' flexDirection='row' alignItems='center' justifyContent='center'
          height='570px'

          position='relative'
        >
          <FlexBox column jcCenter
            width={`${smDown ? '80%' : '70%'}`} height='100%'
          >

            <FlexBox column aiCenter gap="1rem"
              width="100%" height="50%"
            >
              {status ?
                <img
                  src={`${ConfirmEmail}`}
                  style={{ width: '200px' }}
                />
                : ''}


              <Typography variant="h3" fontSize={`${smDown ? '23px' : '28px'}`} fontWeight="bold" color="#6b6b6b">
                {message}
              </Typography>


              <Button variant="outlined"
                onClick={() => nav("/login")}
                sx={{ borderRadius: "10px", marginTop: '2rem' }}>
                Ir para login!
              </Button>

            </FlexBox>

          </FlexBox>

        </Box>
      </Box >
    </>

  );
};

