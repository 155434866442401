import { ReactNode } from 'react';
import { Drawer, useMediaQuery, useTheme } from '@mui/material';

import { useDrawerContext } from '../../../contexts';


interface MenuLateralContainerProps {
  children: ReactNode
}

export const MenuLateralContainer: React.FC<MenuLateralContainerProps> = ({ children }) => {

  const theme = useTheme();
  const { isDrawerOpen, toggleDrawerOpen, } = useDrawerContext();


  const mdDown = useMediaQuery(theme.breakpoints.down('md')); // sm = 600px / md = 900px lg = 1200px

  if (mdDown) {
    return (
      <Drawer open={isDrawerOpen} onClose={toggleDrawerOpen} variant='temporary'>
        {children}
      </Drawer>
    );
  }
  else {
    return (
      <>{children}</>
    )
  };
};
