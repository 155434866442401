import { IRole } from "../../../models";
import { IResponseAPI } from "../../../models/base/IResponseAPI";
import { Api } from "../axios-config";



const insertRole = async (userId: number, branchId: number, level: number): Promise<IResponseAPI<any> | IResponseAPI<any>> => {
  const { data } = await Api.post(`/otica/Role`, { userId, branchId, level }).catch((data) => {
    return data.response;
  });
  return data;
};


const getRoleFake = async (): Promise<any | any> => {
  const { data } = await Api.get(`/otica/Role`).catch((data) => {
    return data.response;
  });
  return data;

};

const getRole = async (): Promise<IResponseAPI<IRole[]> | IResponseAPI<IRole[]>> => {
  const { data } = await Api.get(`/otica/Role`).catch((data) => {
    if (data)
      return data.response;
  });

  if (data.errors === null) {
    const returning: IResponseAPI<IRole[]> = {
      success: data.success,
      message: data.message,
      errors: ["null", "or", "undefined"],
      data: data.data
    }
    return returning
  }else{
    return data;
  }
};


const selectRole = async (id: number): Promise<IResponseAPI<any> | IResponseAPI<any>> => {
  const { data } = await Api.put(`/otica/Role/Select/${id}`).catch((data) => {
    return data.response;
  });
  return data;
};


const putRole = async (roleId: number, level: number): Promise<IResponseAPI<any> | IResponseAPI<any>> => {
  const { data } = await Api.put(`/otica/Role`, { id: roleId, level }).catch((data) => {
    return data.response;
  });
  return data;
};


const deleteRole = async (roleId: number): Promise<IResponseAPI<any> | IResponseAPI<any>> => {
  const { data } = await Api.delete(`/otica/Role/${roleId}`).catch((data) => {
    return data.response;
  });
  return data;
};


export const RoleService = {
  getRole,
  putRole,
  insertRole,
  deleteRole,
  selectRole,
  getRoleFake
};