import { Environment } from "../../../environment";
import { IResponseAmount, IResponseAPI } from "../../../models";
import { Api } from "../axios-config";


export class InsertProdutoDto implements IInsertProdutoDto {
  name: string;
  quantity: number;
  cost: number;
  value: number;

  constructor(name: string, quantity: number, cost: number, value: number) {
    this.name = name;
    this.quantity = quantity;
    this.cost = cost;
    this.value = value;
  }
};

export class ProdutoEstoque implements IProdutoEstoque {
  id: number;
  name: string;
  quantity: number;
  cost: number;
  value: number;

  constructor(id: number, name: string, quantity: number, cost: number, value: number) {
    this.id = id;
    this.name = name;
    this.quantity = quantity;
    this.cost = cost;
    this.value = value;
  }
};


export interface IInsertProdutoDto {
  name: string;
  quantity: number;
  cost: number;
  value: number;
};

export interface IProdutoEstoque {
  id: number;
  name: string;
  quantity: number;
  cost: number;
  value: number;
};

export interface IDetalheEstoque {
  id: number;
  nome: string;
};

interface IDeleteResponse {
  data: boolean;
};

const getAll = async (page = 1, filter = ''): Promise<IResponseAPI<IResponseAmount<IProdutoEstoque[]>> | IResponseAPI<IResponseAmount<IProdutoEstoque[]>>> => {

  const { data } = await Api.get(`/otica/Product`,
    {
      params: {
        page: page,
        pageSize: Environment.LIMITE_DE_LINHAS,
        name: filter,
      },
    }
  ).catch((data) => {
    return data.response;
  });

  return data;
};

const getById = async (id: number): Promise<IResponseAPI<IProdutoEstoque> | IResponseAPI<IProdutoEstoque>> => {
  const { data } = await Api.get(`/otica/Product/${id}`).catch(data => {
    return data.response;
  })
  return data;
};

const create = async (produto: IInsertProdutoDto): Promise<IResponseAPI<IProdutoEstoque> | IResponseAPI<IProdutoEstoque>> => {
  const { data } = await Api.post(`/otica/Product`, produto).catch((data) => {
    return data.response;
  });
  return data;
};

const updateById = async (dados: IProdutoEstoque): Promise<IResponseAPI<IProdutoEstoque> | IResponseAPI<IProdutoEstoque>> => {
  const { data } = await Api.put(`/otica/Product`, dados).catch((data) => {
    return data.response;
  });
  return data;
};

const deleteById = async (id: number): Promise<IResponseAPI<IDeleteResponse> | IResponseAPI<IDeleteResponse>> => {
  const { data } = await Api.delete(`/otica/Product/${id}`).catch(data => {
    return data.response;
  });
  return data;
};


export const EstoquesService = {
  getAll,
  getById,
  create,
  updateById,
  deleteById,
};