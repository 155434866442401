import { TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, Box, Typography, Button } from '@mui/material';
import React from 'react';
import { ISubscription } from '../../../../shared/models/Subscription';
import dayjs from 'dayjs';
import updateLocale from 'dayjs/plugin/updateLocale'
import { IStatusPaymentEnum } from '../../../../shared/models/enums/StatusPaymentEnum';

interface SubscriptionTableProps {
  subscription: ISubscription,
};

export const SubscriptionTable = ({ subscription }: SubscriptionTableProps) => {

  dayjs.extend(updateLocale)

  dayjs.updateLocale('en', {
    months: [
      "Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho",
      "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"
    ]
  })

  return (
    <TableContainer component={Paper} variant='outlined' sx={{ width: 'auto', overflow: 'auto', mt: 2 }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{subscription.branch.name}</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Ações</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {subscription.payments?.map(row => (
            <TableRow key={row.id} sx={{ /*cursor: 'pointer', */ '&:hover': { backgroundColor: '#f3f3f3' }, transition: '.3s' }}
              component={Box}
            >
              <TableCell>
                <Typography>
                  {dayjs(row.dueDate).format('DD-MMM-YYYY')}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography color={row.status == 3 || row.status == 2 ? 'green' : 'orange'}>
                  {IStatusPaymentEnum[row.status]}
                </Typography>
              </TableCell>
              <TableCell>
                {row.status == 3 || row.status == 2 ? null :
                  <a target="_blank" href={row.invoiceURL}>
                    <Button size='small' variant="contained" color="success" onClick={() => { }}>
                      Pagar
                    </Button></a>}

              </TableCell>

            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
};