import { Box, Typography, Paper, Button, Icon, useMediaQuery, Theme, Stack } from '@mui/material';
import { Env } from '../../../../../shared/environment';
import { IDocumentInsert } from '../../../../../shared/models/Clients/document';
import { CardDocument } from '../../CardDocument';

interface SectionDocumentProps {
  rowsDocuments: IDocumentInsert[];
  setRowsDocuments: React.Dispatch<React.SetStateAction<IDocumentInsert[]>>;
  errorDocument: boolean;
}

export const SectionDocument: React.FC<SectionDocumentProps> = (props) => {
  const { rowsDocuments, setRowsDocuments, errorDocument } = props;

  const handleAddCellphone = () => {
    let document: IDocumentInsert = { value: '', type: 0 }
    setRowsDocuments(prevListTel => [...prevListTel, document]);
  }

  const handleDeleteDocument = (indice: number) => {
    if (rowsDocuments.length > 1) {
      setRowsDocuments(prevListTel => prevListTel.filter((_, index) => index !== indice));
    }
    else {
      return;
    };
  };

  const lgDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg')); //   sm = 600px  //  md = 900px  //  lg = 1200px

  return (
    <Box // content inferior
      width='100%' minHeight='200px'
      sx={Env.FLEX_COLUMN} gap={1} boxSizing='border-box'
    >
      <Stack width='100%' height='min-content' sx={lgDown ? Env.FLEX_COLUMN : Env.FLEX_ROW} gap={5} //bgcolor='#3333334f'
      >
        <Paper component={Box} width='100%' boxSizing='border-box' elevation={0} // Telefone Container
        >
          <Box
            width='100%'
            minHeight='100px'
            paddingBottom='15px'
            boxSizing='border-box'
            display='flex'
            alignItems='center'
            flexDirection='column'
            gap='1rem'
            borderRadius='20px'
          >
            <Box
              width='100%'
              minHeight='50px'
              borderRadius='10px'
              display='flex'
              justifyContent='space-between'
              alignItems='center'
              flexDirection='row'
              gap='0rem'
            >
              <Typography>Documentos</Typography>
              <Button
                startIcon={<Icon>add</Icon>}
                onClick={handleAddCellphone}
                variant="contained"
              >
                Documento
              </Button>
            </Box>
            <Box
              width='100%'
              minHeight='100px'
              boxSizing='border-box'
              borderRadius='10px'
              display='flex'
              justifyContent='center'
              alignItems='center'
              flexDirection='column'
              gap='1rem'
            >
              {rowsDocuments.map((_, indice) => (
                <CardDocument
                  handleDeleteDocument={handleDeleteDocument}
                  errorDocument={errorDocument}
                  setRowsDocuments={setRowsDocuments}
                  rowsDocuments={rowsDocuments}
                  indice={indice}
                />
              ))}
            </Box>
          </Box>
        </Paper>
      </Stack>
    </Box>
  )
}