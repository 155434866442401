import { Typography, Button, Icon } from '@mui/material'
import React, { useEffect, useState } from 'react'
import FlexBox from '../../../../../shared/components/divs/flexBox'
import { IDocumentInsert } from '../../../../../shared/models/Clients/document'
import { SectionDocument } from './Modal-NovoCliente-3-document'
import { NewClientStorage } from '../../../../../shared/services/storage/newClientStorage'

interface SectionDocumentProps {
  handleToBack(): void
  handleAdvance(): void
  handleSubmit(): void
  rowsDocuments: IDocumentInsert[];
  setRowsDocuments: React.Dispatch<React.SetStateAction<IDocumentInsert[]>>;
}

export const SectionDocuments: React.FC<SectionDocumentProps> = (props) => {
  const { handleSubmit, handleToBack, rowsDocuments, setRowsDocuments, handleAdvance } = props;

  useEffect(()=>{
    getDocuments()
  },[])

  const getDocuments = () => {
    const storageClient = new NewClientStorage();
    const documentsStorage = storageClient.getDocuments();
    if (!!documentsStorage) {
      setRowsDocuments(documentsStorage);
    }
  }
  const saveDocuments = () =>{
    const storageClient = new NewClientStorage();
    storageClient.saveDocuments(rowsDocuments);
  }

  const back = () =>{
    handleToBack()
    saveDocuments()
  }
  const next = () =>{
    handleSubmit()
    saveDocuments()
  }

  return (
    <FlexBox column gap={5}>
      <FlexBox>
        <SectionDocument
          rowsDocuments={rowsDocuments}
          setRowsDocuments={setRowsDocuments}
          errorDocument={false}
        />
      </FlexBox>
      <FlexBox row jcBetween>
        <Button variant="text" startIcon={<Icon>fast_rewind_rounded</Icon>} onClick={back}>
          Voltar
        </Button>
        <Button variant="contained" endIcon={<Icon>save_rounded</Icon>} onClick={next}>
          Salvar
        </Button>
      </FlexBox>
    </FlexBox>
  )
}
