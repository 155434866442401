import { TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, Box, Avatar, Icon, Typography, IconButton, TableFooter, LinearProgress, Pagination, useMediaQuery, Theme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Environment } from '../../../../shared/environment';
import { IBranch } from '../../../../shared/models/Branch';
import { BranchService } from '../../../../shared/services/api/branch/BranchService';
import { ModalEditBranch } from './ModalEditBranch';



export const BanchListTable = () => {
  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const [openEdit, setOpenEdit] = useState(false);
  const [branchSelect, setBranchSelect] = useState<IBranch>();
  const [rows, setRows] = useState<IBranch[]>();
  const [totalCount, setTotalCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    BranchService.getBranchs()
      .then((result) => {
        if (result != undefined &&result?.success && result?.data != undefined) {
          setRows(result.data);
          setTotalCount(result.data.length)
          setIsLoading(false)
        }
      });
  }, [openEdit]);

  const handleEdit = (branch: IBranch) => {
    setBranchSelect(branch)
    setOpenEdit(true);
  }
  const handleEditClose = () => {
    setOpenEdit(false)
  };


  return (
    <TableContainer component={Paper} variant='outlined' sx={{ width: 'auto', overflow: 'auto' }}>
      <Table>


        <TableHead>

          <TableRow>
            <TableCell></TableCell>
            <TableCell>Nome</TableCell>
            {!smDown ? <TableCell>Endereço</TableCell> : null}
            <TableCell>Ações</TableCell>
          </TableRow>

        </TableHead>



        <TableBody>

          {rows?.map(row => (
            <TableRow key={row.id} sx={{ /*cursor: 'pointer', */ '&:hover': { backgroundColor: '#f3f3f3' }, transition: '.3s' }}
              component={Box}
            >
              <TableCell>
                <Icon sx={{ fontSize: smDown ? 25 : 35 }}>location_city</Icon>
              </TableCell>

              <TableCell>
                <Typography>
                  {row.name}
                </Typography>
              </TableCell>
              {!smDown ?
                <TableCell>
                  <Typography>
                    Exemple
                  </Typography>
                </TableCell> : null}

              <TableCell>
                <IconButton size='small' onClick={() => handleEdit(row)}>
                  <Icon>edit</Icon>
                </IconButton>
              </TableCell>

            </TableRow>
          ))}

        </TableBody>


        {totalCount === 0 && !isLoading && (
          <caption>{Environment.LISTAGEM_VAZIA}</caption>
        )}


        <TableFooter>
          {isLoading && (
            <TableRow>
              <TableCell colSpan={6} // <--- colSpan( qntdade de colunas vai ocupar de espaço )
              >
                <LinearProgress variant='indeterminate' />
              </TableCell>
            </TableRow>
          )}
          {/* {(totalCount > 0 && totalCount > Environment.LIMITE_DE_LINHAS) && (
            <TableRow>
              <TableCell colSpan={3}>
                <Pagination
                  page={pagina}
                  count={Math.ceil(totalCount / Environment.LIMITE_DE_LINHAS)}
                  onChange={(_, newPage) => setSearchParams({ busca, pagina: newPage.toString() }, { replace: true })}
                />
              </TableCell>
            </TableRow>
          )} */}
        </TableFooter>

        <ModalEditBranch
          handleClose={handleEditClose}
          open={openEdit}
          branch={branchSelect as IBranch}
        />
      </Table>
    </TableContainer>
  )
};