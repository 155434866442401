import { useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import { MenuLateral } from "../shared/components";
import { useAuthContext, useDrawerContext } from "../shared/contexts";
import {
    Dashboard,
    DetalheDePessoas,
    ListagemDePessoas,
    DetalheDeCidades,
    ListagemDeCidades,
    ListagemDeClientes,
    DetalheDeClientes,
    ListagemDeProdutos,
    DetalheDeProdutos,
    ListagemDeOrdensDeServicos,
    DetalheDeOrdensDeServicos,
    ListagemDeVendas,
    DetalheDeVendas,
    ListagemDeEstoques,
    DetalheDeProduto,
    ListagemDeCadastros,
    DetalheDeCadastros,
    ConfirmedEmail,
    ForgotPassword,
    ResetPassword,
    UserList,
    DashboardAdmin,
    BranchList,
    Login,
} from "../pages";
import { SubscriptionList } from "../pages/admin/subscriptions/SubscriptionList";




export const AppRouters = () => {


    const { setDrawerOptions, setDrawerOptionsAdmin } = useDrawerContext();
    const { isAuthenticated, user } = useAuthContext();

    useEffect(() => {
        setDrawerOptionsAdmin([
            {
                icon: 'people',
                path: '/admin/users',
                label: 'Usuarios'
            },
            {
                icon: 'location_city',
                path: '/admin/filial',
                label: 'Pontos de venda'
            },
            {
                icon: 'add_business',
                path: '/admin/subscriptions',
                label: 'Assinaturas'
            }
        ])
        setDrawerOptions([
            {
                icon: 'home',
                path: '/painel',
                label: 'Página Inicial'
            },
            {
                icon: 'people',
                path: '/clientes',
                label: 'Clientes'
            },
            // {
            //     icon: 'local_grocery_store',
            //     path: '/produtos',
            //     label: 'Produtos'
            // },
            {
                icon: 'upload_file',
                path: '/ordensDeServicos',
                label: 'Ordens de Serviços'
            },
            {
                icon: 'local_mall',
                path: '/vendas',
                label: 'Vendas'
            },
            {
                icon: 'sync_alt',
                path: '/estoques',
                label: 'Estoque'
            },
            // {
            //     // icon: 'app_registration',
            //     icon: 'location_city',
            //     path: '/cadastros',
            //     label: 'Cadastros'
            // },
        ])
    }, []);

    return (
        <>
            {
                !isAuthenticated && (
                    <Routes>
                        <Route path="/login" element={<Login />} />
                        <Route path="/confirmedEmail" element={<ConfirmedEmail />} />
                        <Route path="/forgotPassword" element={<ForgotPassword />} />
                        <Route path="/ResetPassword" element={<ResetPassword />} />
                        <Route path="*" element={<Navigate to="/login" />} />
                    </Routes>
                )
            }
            {
                isAuthenticated && (
                    <MenuLateral>
                        <Routes>
                            {
                                user.admin &&
                                <>
                                    <Route path="/admin" element={<DashboardAdmin />} />
                                    <Route path="/admin/users" element={<UserList />} />
                                    <Route path="/admin/filial" element={<BranchList />} />
                                    <Route path="/admin/subscriptions" element={<SubscriptionList />} />
                                </>
                            }
                            <Route path="/painel" element={<Dashboard />} />

                            {/* <Route path="/clientes/:id" element={<NovoCliente />} /> */}
                            <Route path="/clientes" element={<ListagemDeClientes />} />
                            <Route path="/clientes/detalhe/:id" element={<DetalheDeClientes />} />

                            <Route path="/produtos" element={<ListagemDeProdutos />} />
                            <Route path="/produtos/detalhe/:id" element={<DetalheDeProdutos />} />

                            <Route path="/ordensDeServicos" element={<ListagemDeOrdensDeServicos />} />
                            <Route path="/ordensDeServicos/detalhe/:id" element={<DetalheDeOrdensDeServicos />} />

                            <Route path="/vendas" element={<ListagemDeVendas />} />
                            <Route path="/vendas/detalhe/:id" element={<DetalheDeVendas />} />

                            <Route path="/estoques" element={<ListagemDeEstoques />} />
                            <Route path="/produto/detalhe/:id" element={<DetalheDeProduto />} />

                            <Route path="/cadastros" element={<ListagemDeCadastros />} />
                            <Route path="/cadastros/detalhe/:id" element={<DetalheDeCadastros />} />

                            <Route path="/pessoas" element={<ListagemDePessoas />} />
                            <Route path="/pessoas/detalhe/:id" element={<DetalheDePessoas />} />

                            <Route path="/cidades" element={<ListagemDeCidades />} />
                            <Route path="/cidades/detalhe/:id" element={<DetalheDeCidades />} />


                            <Route path="*" element={<Navigate to="/painel" />} />
                        </Routes>
                    </MenuLateral>
                )
            }
        </>
    );
};