import { Dispatch, ReactNode, SetStateAction, createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { IUser, Auth, IRole, IResponseAPI } from '../models';
import { AuthService } from '../services/api/auth/AuthService';
import { StorageService } from '../services/storage/storageService';
import { RoleService } from '../services/api/role/RoleService';


interface IAuthContextData {

  user: IUser;
  roles: IRole[];
  logout: () => void;
  globalLoading: boolean;
  isAuthenticated: boolean;
  setRoles: Dispatch<SetStateAction<IRole[]>>;
  setGlobalLoading: Dispatch<SetStateAction<boolean>>;
  login: (email: string, password: string) => Promise<IResponseAPI<Auth>>;

};

const AuthContext = createContext({} as IAuthContextData);

interface IAuthProviderProps {
  children: ReactNode;
}
export const AuthProvider: React.FC<IAuthProviderProps> = ({ children }) => {
  const storage = new StorageService()
  const [accessToken, setAccessToken] = useState<string>();
  const [user, setUser] = useState<IUser>(storage.getUser());
  const [isLoading, setIsLoading] = useState(true);



  const [roles, setRoles] = useState<IRole[]>([])
  const [globalLoading, setGlobalLoading] = useState(false);


  useEffect(() => {
    setIsLoading(true)

    const accessTokenJSON = storage.getToken();
    setUser(storage.getUser())
    if (accessTokenJSON) {
      setAccessToken(accessTokenJSON)
      getRoles()
    } else {
      setAccessToken(undefined);
    }
    setIsLoading(false)
  }, [])

  const getRoles = () => {
      const storage = new StorageService();
      const roles = storage.getRoles();
      if (roles != undefined && roles.length > 0) {
        setRoles(roles)
      } else {
        RoleService.getRole().then(
          result => {
            setGlobalLoading(true);

            if (result.success === true && result.data != undefined) {
              storage.saveRoles(result.data);
              setRoles(result.data)
            } else {
              alert(result.errors[0]);
            }
            setGlobalLoading(false);
          }
        );
      }
  }

  const handleLogin = useCallback(async (email: string, password: string) => {
    const result = await AuthService.auth(email, password);
    if (!result.success) {
      return result;
    } else {
      storage.saveToken(result.data?.accessToken as string);
      storage.saveRefreshToken(result.data?.refreshToken as string);
      storage.saveUser(result.data?.data as IUser);
      storage.saveRoles(result.data?.data.roles as IRole[]);
      setRoles(result.data?.data.roles as IRole[])
      setUser(result.data?.data as IUser)
      setAccessToken(result.data?.accessToken);
      return result;
    }
  }, []);

  const handleLogout = useCallback(() => {
    new StorageService().signOut();
    setAccessToken(undefined);
  }, []);

  const isAuthenticated = useMemo(() => !!accessToken, [accessToken]);
  //     { !!accessToken } é a mesma coisa que:  { accessToken !== undefined }
  return (
    <>
      {
        isLoading ? <p>Carregando...</p> :
          <AuthContext.Provider value={{
            isAuthenticated,
            user,
            login: handleLogin,
            logout: handleLogout,
            roles,
            setRoles,
            globalLoading,
            setGlobalLoading
          }}>
            {children}
          </AuthContext.Provider>
      }
    </>
  )
};



export const useAuthContext = () => useContext(AuthContext);