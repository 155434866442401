import { TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, Avatar, Icon, Typography, Box, IconButton, TableFooter, LinearProgress, Pagination } from "@mui/material";
import { SetURLSearchParams, useNavigate } from "react-router-dom";

import { Environment } from "../../../shared/environment";
import { IClient } from "../../../shared/models/Clients/client";

interface SectionTableProps {
  rows: IClient[] | undefined;
  isLoading: boolean;
  totalCount: number;
  setSearchParams: SetURLSearchParams;
  busca: string;
  pagina: number;
}

export const SectionTable: React.FC<SectionTableProps> = ({
  rows, isLoading, totalCount, setSearchParams, busca, pagina }) => {
  const navigate = useNavigate();

  const styleCell = { display: 'flex', flexDirection: 'row', alignItems: 'center' };

  function formatPhoneNumber(phoneNumberString: string) {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    var match = cleaned.match(/^(\d{2})(\d{5})(\d{4})$/);
    if (match) {
      return ['(', match[1], ') ', match[2], '-', match[3]].join('');
    }
    return null;
  }
  return (
    <TableContainer component={Paper} variant='outlined' sx={{ width: 'auto', overflow: 'auto' }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell width={10}></TableCell>
            <TableCell>Nome</TableCell>
            <TableCell>Contato</TableCell>
            <TableCell>Endereço</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows?.map(row => (
            <TableRow key={row.id} sx={{ cursor: 'pointer', '&:hover': { backgroundColor: '#8d8d8d3d' }, transition: '.3s' }}
              component={Box}
              onClick={() => navigate(`/clientes/detalhe/${row.id}`)}>
              <TableCell>
                <Typography>
                  <Icon>people</Icon>
                </Typography>
              </TableCell>
              <TableCell>
                <Typography fontWeight={500}>{row.name}</Typography>
                {row.branch?.name &&
                  <Typography component={Box} fontSize={12} sx={styleCell}>
                    <Icon >location_city</Icon>
                    {row.branch.name}
                  </Typography>}
              </TableCell>
              <TableCell>
                <Typography>
                  {
                    (row.contacts.filter(x => x.isMain == true).length !== 0) ?
                      row.contacts.filter(x => x.isMain == true && (x.type == 0 || x.type == 1)).length !== 0 ?
                        formatPhoneNumber(row.contacts.filter(x => x.isMain == true && (x.type == 0 || x.type == 1))[0].value) :
                        row.contacts.filter(x => x.isMain == true)[0].value :
                      "(XX) XXXXX-XXXX"
                  }
                </Typography>
              </TableCell>
              <TableCell>
                <Icon>location_on</Icon>
                {
                  row.address === null ? "------- ------" : row?.address?.city
                }
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
        {totalCount === 0 && !isLoading && (
          <caption>{Environment.LISTAGEM_VAZIA}</caption>
        )}
        <TableFooter>
          {isLoading && (
            <TableRow>
              <TableCell colSpan={6}>
                <LinearProgress variant='indeterminate' />
              </TableCell>
            </TableRow>
          )}
          {(totalCount > 0 && totalCount > Environment.LIMITE_DE_LINHAS) && (
            <TableRow>
              <TableCell colSpan={3}>
                <Pagination
                  page={pagina}
                  count={Math.ceil(totalCount / Environment.LIMITE_DE_LINHAS)}
                  onChange={(_, newPage) => setSearchParams({ busca, pagina: newPage.toString() }, { replace: true })}
                />
              </TableCell>
            </TableRow>
          )}
        </TableFooter>
      </Table>
    </TableContainer>
  )
}
