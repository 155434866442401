import { useState } from "react";
import { BanchListTable } from "./sections/BanchList-table";
import { LayoutBaseDePagina } from "../../../shared/layouts";
import { FerramentasDaListagem } from "../../../shared/components";
import { ModalNovaBranch } from "./sections/Modal-NovaBranch";

export const BranchList = () => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpenModalNovo = () => {
    setIsOpen(true);
  };

  return (
    <LayoutBaseDePagina
      titulo="Pontos de Venda"
      barraDeFerramentas={
        <FerramentasDaListagem
          textoBotaoNovo="Adicionar"
          mostrarBotaoNovo
          aoClicarEmNovo={handleOpenModalNovo}
        />
      }
    >
      <BanchListTable />
      <ModalNovaBranch
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />
    </LayoutBaseDePagina>
  )
};

