import { useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Box, Button, Icon, Switch, Theme, Typography, useMediaQuery } from "@mui/material";
import { ClientesService } from "../../shared/services/api/clientes/ClientesService";
import { FerramentasDaListagem } from "../../shared/components";
import { LayoutBaseDePagina } from "../../shared/layouts";
import { useDebounce } from "../../shared/hooks";
import { SectionCardTableMobile, SectionTable } from "./sections";
import { Env } from "../../shared/environment";
import { useAuthContext } from "../../shared/contexts";
import { ModalNovoCLiente2 } from "./components/listagemDeCLientes/Modal-NovoCLiente2";
import { IClient } from "../../shared/models/Clients/client";
import { NewClientStorage } from "../../shared/services/storage/newClientStorage";




export const ListagemDeClientes: React.FC = () => {

  const { user } = useAuthContext();
  const { debounce } = useDebounce(800, true);
  const [searchParams, setSearchParams] = useSearchParams();

  const [rows, setRows] = useState<IClient[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [amount, setAmount] = useState(0);

  // Variáveis do Modal de Criar novo cliente
  const [concluido, setConcluido] = useState(false);


  // const theme = useTheme();




  const busca = useMemo(() => {
    return searchParams.get('busca') || '';
  }, [searchParams]);



  const pagina = useMemo(() => {
    return Number(searchParams.get('pagina') || '1');
  }, [searchParams]);


  const getClientAdmin = () => {
    debounce(() => {
      ClientesService.get(pagina, busca).then(
        result => {
          setIsLoading(false);

          if (result instanceof Error) {
            alert(result.message);
          }
          else
            if (result?.success && result.data !== undefined && result.data !== null) {
              console.log(result.data);
              setAmount(result.data.amount);
              setRows(result.data.data);
            }
        }
      )
    })
  };

  const getClientComum = () => {
    debounce(() => {
      ClientesService.getComum(pagina, busca).then(
        result => {
          setIsLoading(false);
          
          if (result.success) {
            console.log(result.data);
            setAmount(result!.data!.amount);
            setRows(result!.data!.data);
          }else{
            alert(result.errors + result.message);
          };
        }
      );
    });
  };

  

  useEffect(() => {
    setIsLoading(true);
    const userRole = user.roles.filter(role => role.level === 0);
    !userRole && getClientComum();
    userRole && getClientAdmin();

  }, [busca, pagina, debounce]);





  // const mdDown = useMediaQuery(theme.breakpoints.down('md')); /////////////////////  sm = 600px / md = 900px lg = 1200px
  const lgDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg')); //   sm = 600px  //  md = 900px  //  lg = 1200px
  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm')); //   sm = 600px  //  md = 900px  //  lg = 1200px

  // const color =  theme.palette.primary.main;

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    // if (concluido) {
    //   setForShow(0);
    // }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false)
  };



  return (
    <LayoutBaseDePagina
      titulo="Listagem de Clientes"
      barraDeFerramentas={
        <FerramentasDaListagem
          mostrarInputBusca
          textoDaBusca={busca}
          textoBotaoNovo="Novo Cliente"
          // aoClicarEmNovo={() => navigate('/clientes/novo')}
          aoClicarEmNovo={handleOpen}
          aoMudarTextDeBusca={texto => setSearchParams({ busca: texto, pagina: '1' }, { replace: true })}
        />
      }
    >

      <ModalNovoCLiente2
        handleClose={handleClose}
        setRows={setRows}
        open={open}
      />

      <Box marginY={2}
        sx={lgDown ? Env.FLEX_COLUMN : Env.FLEX_ROW}
        justifyContent='space-between'
        gap={2}
      >
        <Button variant="contained" endIcon={<Icon>add</Icon>} sx={{ width: '220px' }}
        >
          Exportar Clientes
        </Button>
        <Switch checked={concluido} />
        <Typography
          variant="h6"
          sx={smDown ? { fontSize: '15px' } : {}}
        >
          Foram encontrados um total de {amount} clientes
        </Typography>
      </Box>

      {!smDown && (
        <SectionTable
          pagina={pagina}
          busca={busca}
          isLoading={isLoading}
          rows={rows}
          //  setRows={setRows}
          setSearchParams={setSearchParams}
          totalCount={amount}
        />)
      }

      {
        smDown && (
          <SectionCardTableMobile
            rows={rows}
          />
        )
      }

    </LayoutBaseDePagina>
  )
}