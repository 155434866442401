import { IResponseAPI } from "../../../models/base/IResponseAPI";
import { ISubscription } from "../../../models/Subscription";
import { Api } from "../axios-config";

const getSubscriptions = async (): Promise<IResponseAPI<ISubscription[]> | IResponseAPI<ISubscription[]>> => {
  const { data } = await Api.get(`/otica/Subscripttion`).catch((data) => {
    return data.response;
  });
  return data;
};

export const SubscriptionService = {
    getSubscriptions
};